/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { isMobile } from '~/easy-components/DeviceDetect';
import { useDispatch, useSelector } from 'react-redux';

import { sendData } from '~/store/modules/log/actions';
// eslint-disable-next-line import/no-cycle
import InputTable from '~/easy-components/Form/InputTable';
import CustomTab from '~/easy-components/TabContent/CustomTab';
import ListTab from '~/easy-components/TabContent/ListTab';
import ScheduleTab from '~/easy-components/TabContent/ScheduleTab';
import TimelineTab from '~/easy-components/TabContent/TimelineTab';
import AttachmentTab from '~/easy-components/TabContent/AttachmentTab';
import useComponentState from '~/hooks/useComponentState';
import useUiDesigner from '~/hooks/useUiDesigner';
import Content from '../Content';
import Gantt from '../Form/Gantt';
import CustomIcon from '../Icon';
import { colors } from '../styles';
import { Container, Header, Tab } from './styles';

const TabContent = forwardRef(
  (
    {
      backgroundColor,
      tabColor,
      selectedColor,
      children,
      settings,
      formRef,
      customTabsSettings,
      onChangeTab,
      isReadOnly,
      headerPosition: headerPositionProp,
      activeTab: activeTabProp,
      onTabClick,
      onTabGridUploadInLine,
      onTabGridSuccessUploadInLine,
      onTabGridValidateUploadInLine,
      onTabGridDeleteUploadInLine,
      onTabGridSuccessDeleteUploadInLine,
      onTabGridGetDownloadToken,
      onTabGridOpenInputLinkerInLine,
      gridAuxScope: aux2,
      showLoading,
      t,
    },
    ref
  ) => {
    const gridAuxScope = {
      ...settings.dynamicFunctionProps,
      globalFunctions: settings.globalFunctions,
      ...aux2,
    };

    const dispatch = useDispatch();

    const showLog = useSelector(({ log }) => log.isShow);

    const isFirstRender = useRef(true);

    const pageId = settings ? settings._route : '';

    const { viewMode, showContextMenu } = useUiDesigner({
      pageId,
      componentType: 'tabContent',
    });

    const [
      componentState,
      setComponentState,
      updateComponent,
      getComponentState,
    ] = useComponentState({
      tabs: [],
      activeTab: null,
    });

    const { tabs, activeTab } = componentState;

    const oldActiveTabRef = useRef(activeTabProp);

    const { fields } = settings || {};

    const setVisibleTab = (tabName, status) => {
      setTimeout(() => {
        const stateTabs = getComponentState().tabs;

        const newTabs = stateTabs.map(tab => {
          if (tabName !== tab.props.name && tabName !== tab.key) {
            return tab;
          }

          if (tab.$$typeof === Symbol.for('react.element')) {
            return React.cloneElement(tab, {
              hidden: !status,
            });
          }

          return { ...tab, hidden: !status };
        });

        setComponentState('tabs', newTabs, true);

        setComponentState('activeTab', null);

        updateComponent();
      }, 300);
    };

    if (settings.dynamicFunctionProps) {
      settings.dynamicFunctionProps.setVisibleTab = setVisibleTab;
    }

    function onSelectTab(idx, forceSelect = false) {
      if (idx !== activeTab || forceSelect) {
        setComponentState('activeTab', idx, true);

        if (onChangeTab) {
          onChangeTab(tabs[idx || 0].props, idx, oldActiveTabRef.current);
        }

        oldActiveTabRef.current = idx;
      }
      onTabClick(tabs[idx || 0].props, idx);
    }

    useImperativeHandle(ref, () => {
      return {
        setActiveTab: tabName => {
          const index = tabs.findIndex(tab => tab.props.name === tabName);
          onSelectTab(index);
        },
        setVisibleTab,
      };
    });

    const getCustomTabs = () => {
      if (settings && settings.components) {
        const customTabs = settings.components.filter(
          component =>
            component.type === 'tab' ||
            component.type === 'tabList' ||
            component.type === 'tabSchedule' ||
            component.type === 'tabGrid' ||
            component.type === 'tabTimeline' ||
            component.type === 'tabGantt' ||
            component.type === 'tabAttachment'
        );

        const cTabs = customTabs.map(tab => {
          const readOnlyTab =
            customTabsSettings.isReadOnly || tab.IsReadOnly || isReadOnly;

          switch (tab.type) {
            case 'tabGantt':
              return (
                <Gantt
                  hidden={tab.hidden}
                  isReadOnly={readOnlyTab}
                  title={tab.title}
                  name={tab.name}
                  icon={tab.icon}
                  formRef={formRef}
                  settings={settings}
                  isCustomTab
                  isUserField
                />
              );

            case 'tabTimeline':
              return (
                <TimelineTab
                  hidden={tab.hidden}
                  key={tab.name}
                  name={tab.fieldName}
                  title={tab.title}
                  icon={tab.icon}
                  formRef={formRef}
                  settings={settings}
                  auxScope={gridAuxScope}
                  isReadOnly={readOnlyTab}
                  isCustomTab
                  pageId={pageId}
                  isUserField
                />
              );

            case 'tabList':
              return (
                <ListTab
                  hidden={tab.hidden}
                  key={tab.name}
                  name={tab.fieldName}
                  isDraggable={tab.isDraggable}
                  title={tab.title}
                  icon={tab.icon}
                  columns={tab.columns}
                  formRef={formRef}
                  settings={settings}
                  listTemplate={tab.listTemplate}
                  isListTemplateFunction={tab.listTemplateFunction}
                  auxScope={gridAuxScope}
                  isReadOnly={readOnlyTab}
                  isCustomTab
                  pageId={pageId}
                  isUserField
                />
              );

            case 'tabGrid':
              return (
                <InputTable
                  hidden={tab.hidden}
                  key={tab.name}
                  name={tab.fieldName}
                  title={tab.title}
                  icon={tab.icon}
                  formRef={formRef}
                  settings={settings}
                  t={t}
                  tableSettings={tab.settings}
                  showLoading={showLoading}
                  onUploadInLine={onTabGridUploadInLine}
                  onSuccessUploadInLine={onTabGridSuccessUploadInLine}
                  onValidateUploadInLine={onTabGridValidateUploadInLine}
                  onDeleteUploadInLine={onTabGridDeleteUploadInLine}
                  onSuccessDeleteUploadInLine={
                    onTabGridSuccessDeleteUploadInLine
                  }
                  onGetDownloadToken={onTabGridGetDownloadToken}
                  onOpenInputLinkerInLine={onTabGridOpenInputLinkerInLine}
                  auxScope={gridAuxScope}
                  isReadOnly={readOnlyTab}
                  isCustomTab
                  pageId={pageId}
                  isUserField
                />
              );

            case 'tabSchedule':
              return (
                <ScheduleTab
                  name={tab.fieldName}
                  icon={tab.icon}
                  title={tab.title}
                  defaultView={tab.defaultView}
                  endAccessor={tab.endAccessor}
                  endTimeAccessor={tab.endTimeAccessor}
                  isDraggable={tab.isDraggable}
                  onAddEvent={tab.onAddEvent}
                  startAccessor={tab.startAccessor}
                  titleAccessor={tab.titleAccessor}
                  startTimeAccessor={tab.startTimeAccessor}
                  key={tab.name}
                  keyField={tab.keyField}
                  clientEvents={tab.events}
                  eventTemplate={tab.eventTemplate}
                  settings={settings}
                  stringToDate={settings.dynamicFunctionProps.stringToDate}
                  formRef={formRef}
                  isReadOnly={readOnlyTab}
                  isCustomTab
                  pageId={pageId}
                  isUserField
                />
              );

            case 'tab':
              return (
                <CustomTab
                  hidden={tab.hidden}
                  key={tab.name}
                  name={tab.name}
                  title={tab.title}
                  icon={tab.icon}
                  columns={tab.columns}
                  textArea={tab.textArea}
                  textAreaName={tab.textAreaName}
                  formRef={formRef}
                  settings={settings}
                  isReadOnly={readOnlyTab}
                  auxScope={gridAuxScope}
                  isCustomTab
                  pageId={pageId}
                  t={t}
                  isUserField
                />
              );

            case 'tabAttachment':
              return (
                <AttachmentTab
                  name={tab.name}
                  title={tab.title}
                  icon={tab.icon}
                  accept={tab.accept}
                  message={tab.message}
                  isShowPreview={tab.isShowPreview}
                  isReadOnly={tab.isReadOnly}
                />
              );

            default:
              return null;
          }
        });

        return cTabs;
      }

      return null;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      let itemTabs = [];
      const customTabs = getCustomTabs();

      if (Array.isArray(children)) {
        children.forEach(tab => {
          if (Array.isArray(tab)) {
            tab.forEach(item => {
              if (item)
                itemTabs.push({
                  ...item,
                  hidden: item.hidden,
                });
            });
          } else if (tab) {
            itemTabs.push({
              ...tab,
              hidden: tab.hidden,
            });
          }
        });

        if (customTabs) {
          itemTabs = [...itemTabs, ...customTabs];
        }
        setComponentState('tabs', itemTabs);
      } else {
        if (children) itemTabs.push(children);

        if (customTabs) itemTabs = [...itemTabs, ...customTabs];

        setComponentState('tabs', itemTabs);
      }

      if (activeTab === null) {
        setComponentState('activeTab', activeTabProp);
      }

      updateComponent();

      // }, [formRef, children, isReadOnly, settings, activeTabProp]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      formRef,
      children,
      isReadOnly,
      settings,
      // getCustomTabs,
      // activeTab,
      // updateComponent,
      // setComponentState,
      // activeTabProp,
    ]);

    useEffect(() => {
      if (tabs.length > 0 && isFirstRender.current) {
        isFirstRender.current = false;

        onSelectTab(activeTabProp, true);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabs]);

    const headerPosition = isMobile ? 'top' : headerPositionProp;

    return (
      <Container headerPosition={headerPosition}>
        <Header
          headerPosition={headerPosition}
          backgroundColor={backgroundColor}
          hidden={tabs.length === 1}
        >
          {tabs.map((tab, idx) => {
            let Icon = '';
            if (typeof tab.props.icon !== 'function') {
              Icon = (
                <CustomIcon
                  key={idx}
                  name={tab.props.icon}
                  size={32}
                  color={idx === (activeTab || 0) ? selectedColor : tabColor}
                />
              );
            } else {
              const ObjIcon = tab.props.icon;
              Icon = (
                <ObjIcon
                  key={idx}
                  size={32}
                  color={idx === (activeTab || 0) ? selectedColor : tabColor}
                />
              );
            }

            const selfField =
              (fields ? fields.find(f => f.name === tab.props.name) : {}) || {};

            return (
              <Tab
                headerPosition={headerPosition}
                isCustomTab={tab.props.isCustomTab}
                isUserField={tab.props.isUserField}
                hidden={
                  selfField.hidden || tab.props.hidden || tab.hidden || false
                }
                viewMode={viewMode}
                key={idx}
                onClick={() => onSelectTab(idx)}
                tabColor={tabColor}
                selectedColor={selectedColor}
                selected={idx === (activeTab || 0)}
                onMouseEnter={() => {
                  if (showLog) {
                    dispatch(
                      sendData({
                        baseName: null,
                        name: tab.key || tab.props.name,
                        value: null,
                      })
                    );
                  }
                }}
                onContextMenu={event => {
                  showContextMenu({
                    event,
                    tabName: tab.props.name,
                    isCustomTab: tab.props.isCustomTab,
                  });
                }}
              >
                {tab.props.icon && Icon}
                <span>{tab.props.title}</span>
              </Tab>
            );
          })}
          <Tab
            headerPosition={headerPosition}
            style={{ flex: 1 }}
            hidden={false}
            phantom
            onClick={() => {}}
            tabColor={tabColor}
            selectedColor={selectedColor}
            selected={false}
            onMouseEnter={() => {}}
          >
            <span />
          </Tab>
        </Header>
        {tabs.map((tab, idx) => (
          <Content key={idx} visible={idx === (activeTab || 0)}>
            {tab}
          </Content>
        ))}
      </Container>
    );
  }
);

TabContent.propTypes = {
  settings: PropTypes.shape(),
  customTabsSettings: PropTypes.shape(),
  selectedColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  formRef: PropTypes.shape().isRequired,
  tabColor: PropTypes.string,
  isReadOnly: PropTypes.bool,
  headerPosition: PropTypes.string,
  activeTab: PropTypes.number,
  onChangeTab: PropTypes.func,
  onTabClick: PropTypes.func,
  onTabGridUploadInLine: PropTypes.func,
  onTabGridSuccessUploadInLine: PropTypes.func,
  onTabGridValidateUploadInLine: PropTypes.func,
  onTabGridDeleteUploadInLine: PropTypes.func,
  onTabGridSuccessDeleteUploadInLine: PropTypes.func,
  onTabGridGetDownloadToken: PropTypes.func,
  onTabGridOpenInputLinkerInLine: PropTypes.func,
  showLoading: PropTypes.func,
  gridAuxScope: PropTypes.shape(),
};

TabContent.defaultProps = {
  tabColor: colors.tabColor,
  selectedColor: colors.tabColorActive,
  backgroundColor: '#fff',
  settings: {},
  customTabsSettings: {},
  isReadOnly: false,
  headerPosition: 'top',
  activeTab: null,
  onChangeTab: () => {},
  onTabClick: () => {},
  onTabGridUploadInLine: () => {},
  onTabGridSuccessUploadInLine: () => {},
  onTabGridValidateUploadInLine: () => {},
  onTabGridDeleteUploadInLine: () => {},
  onTabGridSuccessDeleteUploadInLine: () => {},
  onTabGridGetDownloadToken: () => {},
  onTabGridOpenInputLinkerInLine: () => {},
  showLoading: () => {},
  gridAuxScope: {},
};

export default TabContent;
