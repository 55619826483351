/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-cycle */
import React, { useRef, useState, useEffect } from 'react';
import Modal from '~/easy-components/Modal';
import Icon from '~/easy-components/Icon';
import useLocale from '~/hooks/useLocale';
import Board from './Board';
import Element from './Element';
import Switch from '../Switch';
import MobileBoards from './MobileBoards';
import {
  Container,
  ElementContent,
  Status,
  Buttons,
  DropElement,
} from './styles';

function Kanban({
  mobileTitle,
  renderTitle,
  boards,
  isCardTemplate,
  isBoardTemplate,
  elements: cards,
  propBoardKey,
  propTitle,
  propPercent,
  renderElement,
  propElementLinkBoard,
  propElementKey,
  onDrop,
  onDrag,
  onClick,
  actions,
  onAdd,
  enableAdd,
  elementTitle,
  elementStatusColor,
  isReadOnly,
  cardButtons,
}) {
  const t = useLocale('_Global');
  const elementRef = useRef(null);
  const [elements, setElements] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);
  const [menuInfo, setMenuInfo] = useState({
    status: false,
    element: null,
  });

  const [dragOverBoard, setDragOverBoard] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [draggingFromBoard, setDraggingFromBoard] = useState(null);
  const indexToRef = useRef(null);

  const getElementsByBoard = board => {
    const elementsBoard = elements.filter(
      e => e[propElementLinkBoard] === board[propBoardKey]
    );

    return elementsBoard;
  };

  const handlerRenderElement = element => {
    return renderElement(element);
  };

  const getBoardByKey = (boardKey, isMobile) => {
    if (boards.length === 0) return null;

    let board = boards.find(b => b[propBoardKey] === boardKey);

    if (!board) {
      board = boards[0];
      setSelectedKey(board[propBoardKey]);
    }

    const title = renderTitle ? renderTitle(board) : board[propTitle];

    return (
      <Board
        isMobile={isMobile}
        key={board[propBoardKey]}
        title={title}
        mobileTitle={mobileTitle}
        percent={propPercent && board[propPercent]}
        propPercent={propPercent}
        boards={boards}
        renderTitle={renderTitle}
        isBoardTemplate={isBoardTemplate}
        setSelectedKey={setSelectedKey}
        propBoardKey={propBoardKey}
        actions={actions}
        enableAdd={enableAdd}
        board={board}
        onAdd={onAdd}
        onDrop={async ({ dropzone, elementDragged }) => {
          if (indexToRef.current === null) {
            if (board[propBoardKey] === draggingFromBoard) {
              resetDragState();
              return;
            }
            indexToRef.current = getElementsByBoard(board).length;
          }

          let draggedElementIndex = elements.findIndex(
            el => el[propElementKey] === elementRef.current[propElementKey]
          );

          // const draggedElementIndex = indexToRef.current;

          const fromBoard = boards.find(
            b => b[propBoardKey] === draggingFromBoard
          );

          onDrop({
            element: elementRef.current,
            elementDragged,
            isMobile: false,
            // updatedElements: newElements,
            updatedElements: elements,
            board,
            //index: dragOverIndex,
            index: indexToRef.current,
            fromIndex: draggedElementIndex,
            fromBoard,
          });

          const contentElements = dropzone.querySelector('.content');

          function addElementInContentElementsPosition(
            position,
            contentElements,
            element
          ) {
            const newContentElements = Array.from(contentElements.children);
            newContentElements.splice(position + 1, 0, element);
            contentElements.innerHTML = '';
            newContentElements.forEach(el => contentElements.appendChild(el));
          }

          addElementInContentElementsPosition(
            draggedElementIndex,
            contentElements,
            elementDragged
          );

          resetDragState();
        }}
        onDragOver={e => {
          e.preventDefault();
          setDragOverBoard(board[propBoardKey]);
        }}
      >
        {getElementsByBoard(board).map((element, index) => {
          const key = element ? element[propElementKey] : 'lastItem';

          return (
            <React.Fragment key={key}>
              {dragOverBoard === board[propBoardKey] &&
                dragOverIndex === index && (
                  <DropElement
                    // className="placeholder"
                    // style={placeholderStyle}
                    onDragLeave={e => {
                      e.preventDefault();
                      indexToRef.current = null;
                      e.currentTarget.classList.remove('board-enter');
                    }}
                    onDragOver={e => {
                      e.preventDefault();
                      e.currentTarget.classList.add('board-enter');
                    }}
                    onDragEnter={() => {
                      indexToRef.current = index;
                    }}
                  />
                )}
              <Element
                className="element-class"
                style={{
                  backgroundColor: element ? element._BgColor : 'transparent',
                }}
                isReadOnly={isReadOnly}
                cardButtons={cardButtons}
                onDragEnter={() => {
                  indexToRef.current = null;
                }}
                onDragStart={() => handleDragStart(element, board)}
                onDragOver={e => handleDragOver(e, board, index)}
                onDragEnd={resetDragState}
                onClick={e => handleClick(e, element)}
              >
                {isCardTemplate ? (
                  handlerRenderElement(element)
                ) : (
                  <>
                    <Status color={element[elementStatusColor]} />
                    <ElementContent>
                      <header>
                        <h1>{element[elementTitle]}</h1>
                        <Modal isOpened={menuInfo.status} direction="right">
                          <MobileBoards
                            title={t('MoveTo')}
                            propPercent={propPercent}
                            mobileTitle={t('MoveTo')}
                            boards={boards}
                            renderTitle={renderTitle}
                            onClose={() => closeMenu()}
                            setSelectedKey={handleMobileDrop}
                            propBoardKey={propBoardKey}
                          />
                        </Modal>
                      </header>
                      {handlerRenderElement(element)}
                      {cardButtons && (
                        <Buttons>
                          {cardButtons.map(button => (
                            <Icon
                              key={button.icon}
                              name={button.icon}
                              color={button.color || '#5699FF'}
                              size={24}
                              onClick={async e => {
                                e.stopPropagation();
                                e.preventDefault();
                                await button.handler({
                                  card: element,
                                  board,
                                });
                              }}
                            />
                          ))}
                        </Buttons>
                      )}
                    </ElementContent>
                  </>
                )}
              </Element>
              {dragOverBoard === board[propBoardKey] &&
                dragOverIndex === index && (
                  <DropElement
                    // style={placeholderStyle}
                    onDragLeave={e => {
                      e.preventDefault();
                      indexToRef.current = null;
                      e.currentTarget.classList.remove('board-enter');
                    }}
                    onDragOver={e => {
                      e.preventDefault();
                      e.currentTarget.classList.add('board-enter');
                    }}
                    onDragEnter={() => {
                      indexToRef.current = index + 1;
                    }}
                  />
                )}
            </React.Fragment>
          );
        })}
      </Board>
    );
  };

  const handleDragStart = (element, board) => {
    elementRef.current = element;
    setDraggingFromBoard(board[propBoardKey]);
    if (onDrag) onDrag(element);
  };

  const handleDragOver = (e, board, index) => {
    e.preventDefault();
    setDragOverBoard(board[propBoardKey]);
    setDragOverIndex(index);
  };

  const handleClick = (e, element) => {
    e.preventDefault();
    e.stopPropagation();
    if (element) {
      onClick(element);
    }
  };

  const handleMobileDrop = key => {
    const selectedBoard = boards.find(b => b[propBoardKey] === key);

    onDrop({
      element: menuInfo.element,
      board: selectedBoard,
      isMobile: true,
      fromBoard: draggingFromBoard,
    });
  };

  const closeMenu = () => {
    setMenuInfo({ ...menuInfo, status: false });
  };

  const resetDragState = () => {
    setDragOverBoard(null);
    setDragOverIndex(null);
    elementRef.current = null;
    setDraggingFromBoard(null);
  };

  useEffect(() => {
    if (boards && boards.length > 0) setSelectedKey(boards[0][propBoardKey]);
  }, [boards, propBoardKey]);

  useEffect(() => {
    setElements(cards);
  }, [cards]);

  const placeholderStyle = {
    minHeight: '60px',
    backgroundColor: 'rgba(0,0,0,0.1)',
    border: '2px dashed rgba(0,0,0,0.2)',
    margin: '0 0 10px 0',
    borderRadius: '4px',
  };

  return (
    <Container>
      <Switch
        computer={boards.map(board =>
          getBoardByKey(board[propBoardKey], false)
        )}
        mobile={getBoardByKey(selectedKey, true)}
      />
    </Container>
  );
}

export default Kanban;
