/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-case-declarations */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { FaBars } from '@react-icons/all-files/fa/FaBars';
import { AiOutlineLoading } from '@react-icons/all-files/ai/AiOutlineLoading';
import { isMobile } from '~/easy-components/DeviceDetect';
import Toast from '~/easy-components/Toast';
import useLocale from '~/hooks/useLocale';
import CustomEvent from '~/easy-components/Form/components/CustomEvent';
import useUiDesigner from '~/hooks/useUiDesigner';
import useFieldBase from '~/hooks/useField';
import LinkIcon from '../../LinkIcon';
import Modal from '../../Modal';
import Switch from '../../Switch';
import TreatError from '../../TreatError';
import SelectItem from '../../SelectItem';
import { sendEvent } from '../../HandlerEvent';
import UserField from '../UserField';
import { FieldContainer, Title } from '../styles';
import { Input, PanelInput, SearchIcon, LoadIcon } from './styles';
import MobileView from './MobileView';
import Detail from './MobileView/Detail';

function LinkerElement(
  {
    baseName,
    name,
    valueField,
    label,
    hidden,
    formRef,
    mainFormRef,
    settings,
    labelWidth,
    readOnly: readOnlyProp,
    method: clientMethod,
    onChange,
    fixedData,
    onLinkClick,
    showLink,
    fieldDefaultValue,
    propFieldValue = 'value',
    propFieldLabel = 'label',
    propFieldAuxInfo = 'AuxInfo',
    renderItem,
    auxScope,
    forceSelection = true,
    onStartLoading,
    onStopLoading,
    labelPosition = 'left',
    required = false,
    initialFilterMobileText = true,
    queryCode,
    disableUnform = false,
    isUserField,
    isCustomTab,
    customTabName,
    ...rest
  },
  ref
) {
  const t = useLocale('_Global');

  const renderized = useRef(false);

  useEffect(() => {
    renderized.current = true;
    return () => {
      renderized.current = false;
    };
  }, []);

  const pageId = settings ? settings._route : '';

  const {
    selfField,
    viewMode,
    showContextMenu,
    HasEventComponent,
  } = useUiDesigner({
    pageId,
    componentType: 'field',
    settings,
    baseName,
    name,
    valueField,
    title: label,
    isUserField,
    customTabName,
    contextProps: {
      queryCode,
      elementType: 'Linker',
      isShowLinkClick: showLink && (isUserField === true || !!customTabName),
    },
  });

  const [isShowSearchViewer, setIsShowSearchViewer] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mobileText, setMobileText] = useState(null);
  const [isShowLink, setIsShowLink] = useState(showLink);

  const inputRef = useRef(null);
  const valueRef = useRef(null);
  const mobileRef = useRef(null);

  const fieldTitle = selfField.title || label;

  const commitLoading = useCallback(loading => {
    if (onStartLoading && typeof onStartLoading === 'function' && loading)
      onStartLoading();

    setIsLoading(loading);

    if (onStopLoading && typeof onStopLoading === 'function' && !loading)
      onStopLoading();
  }, []);

  const {
    fieldName,
    defaultValue = fieldDefaultValue || '',
    registerField,
    error,
  } = useField(name);

  const { isHidden, isRequired, isReadOnly, setIsReadOnly } = useFieldBase({
    hidden,
    readOnly: readOnlyProp,
    required,
    selfField,
    formRef,
    inputRef,
    name,
    getLogData: () => {
      const dataToSend = [
        { baseName, name: fieldName, value: inputRef.current.value },
      ];

      if (valueFieldName !== fieldName) {
        dataToSend.push({
          baseName,
          name: valueFieldName,
          value: valueRef.current.value,
        });
      }

      if (queryCode) {
        dataToSend.push({ baseName, name: 'Consulta', value: queryCode });
      }

      return dataToSend;
    },
  });

  useEffect(() => {
    if (disableUnform) return;

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue: el => {
        el.value = null;
        setMobileText(null);
      },
      setValue: (el, v) => {
        if (fixedData || selfField.fixedData) {
          const value = v === undefined || v === null ? fieldDefaultValue : v;

          if (value === undefined || value === null) {
            el.value = '';
            setMobileText('');
            valueRef.current.value = null;
            return;
          }

          const fixData = selfField.fixedData || fixedData;

          const selectedData = fixData.find(
            d => d.value.toString() === value.toString()
          );

          if (!selectedData) {
            el.value = '';
            setMobileText('');
            valueRef.current.value = null;
            return;
          }

          const visibleText = selectedData[propFieldLabel];
          el.value = visibleText;
          setMobileText(visibleText);
          valueRef.current.value = selectedData[propFieldValue];
          return;
        }

        if (v === undefined && valueRef.current.value === '') {
          const visibleText = '';
          el.value = visibleText;
          setMobileText(visibleText);
          return;
        }

        if (v === undefined) {
          const visibleText = '';
          el.value = visibleText;
          setMobileText(visibleText);
          return;
        }

        if (v === null) {
          const visibleText = '';
          el.value = visibleText;
          setMobileText(visibleText);
          return;
        }

        el.value = v;
        setMobileText(v);
      },
      getValue: el => {
        const fixData = selfField.fixedData || fixedData;

        if (fixData) {
          const selectedData = fixData.find(d => {
            const val =
              d[propFieldLabel] === null || d[propFieldLabel] === undefined
                ? d[propFieldValue]
                : d[propFieldLabel];

            return val.toString() === el.value.toString();
          });
          return selectedData ? selectedData.value : null;
        }

        return el.value === '' ? null : el.value;
      },
    });
  }, []);

  const {
    fieldName: valueFieldName,
    defaultValue: valueDefaultValue = fieldDefaultValue || null,
    registerField: valueRegisterField,
    error: valueError,
  } = useField(valueField);

  useEffect(() => {
    if (valueFieldName !== fieldName)
      valueRegisterField({
        name: valueFieldName,
        ref: valueRef.current,
        path: 'value',
        getValue: el => {
          return el.value === '' ? null : el.value;
        },
      });
  }, [fieldName, valueFieldName, valueRegisterField]);

  function onFocus() {
    const text = inputRef.current.value;
    inputRef.current.setSelectionRange(0, text.length);
  }

  const handlerEvent = async (eventName, params, run) => {
    const events = rest.events || selfField.events || [];

    const response = await sendEvent({
      settings,
      eventName,
      params,
      run,
      formRef,
      mainFormRef,
      element: inputRef.current,
      events,
      auxScope,
    });

    return response || params;
  };

  async function handleChange(data) {
    try {
      // TODO: Verificar pq o formRef não traz os eventos adicionados no form quando o tipo do eento é before
      // após o evento onChange o formRef se normaliza, exibindo os novos métodos
      const newValue = await handlerEvent('onchange', data, 'before');
      await onChange(newValue, inputRef, valueRef);
      await handlerEvent('onchange', data, 'after');
    } catch (e) {
      TreatError.showError(e);
    }
  }

  const onChanged = async () => {
    await handleChange({
      value: valueRef.current.value,
      label: inputRef.current.value,
      element: inputRef.current,
    });
  };

  useEffect(() => {
    inputRef.current.fireChange = async data => {
      await handleChange(data);
    };

    inputRef.current.showLink = status => {
      setIsShowLink(status);
    };

    inputRef.current.addEventListener('changed', onChanged);
    inputRef.current.addEventListener('focus', onFocus);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (inputRef.current && inputRef.current.removeEventListener) {
        inputRef.current.removeEventListener('changed', onChanged);
        inputRef.current.removeEventListener('focus', onFocus);
      }
    };
  }, [selfField]);

  useEffect(() => {
    valueRef.current.fireChange = async data => {
      await handleChange(data);
    };

    valueRef.current.showLink = status => {
      setIsShowLink(status);
    };

    valueRef.current.addEventListener('changed', onChanged);
    valueRef.current.addEventListener('focus', onFocus);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (valueRef.current && valueRef.current.removeEventListener) {
        valueRef.current.removeEventListener('changed', onChanged);
        valueRef.current.removeEventListener('focus', onFocus);
      }
    };
  }, [selfField]);

  useEffect(() => {
    const fixData = selfField.fixedData || fixedData;

    if (fixData && fixData.length > 0) {
      const defValue = valueRef.current.value || fieldDefaultValue;

      if (defValue !== null && defValue !== undefined) {
        const selectedData = fixData.find(
          d => d.value.toString() === defValue.toString()
        );

        valueRef.current.value = selectedData
          ? selectedData[propFieldValue]
          : null;
        inputRef.current.value = selectedData
          ? selectedData[propFieldLabel]
          : null;

        setMobileText(inputRef.current.value);
      }
    }
  }, [fieldDefaultValue]);

  async function onSelect(data, setFocus = true) {
    setIsShowSearchViewer(false);
    commitLoading(false);

    if (inputRef.current) {
      inputRef.current.changed = false;
      if (!disableUnform) {
        formRef.current.setFieldError(fieldName, null);
      }

      if (data) {
        inputRef.current.value = data[propFieldLabel];
        valueRef.current.value = data[propFieldValue];

        if (data._isDraftItem) {
          inputRef.current._isDraftItem = data._isDraftItem;
          valueRef.current._isDraftItem = data._isDraftItem;
        }
      } else {
        inputRef.current.value = null;
        valueRef.current.value = null;
      }

      if (setFocus) inputRef.current.focus();

      await handleChange(data);
    }
  }

  async function onBlur(e) {
    e.persist();

    const textChanged = e.target.changed;
    const searchTextInput = e.target.value;

    if (textChanged && isLoading === false) {
      if (searchTextInput === '' || searchTextInput === null) {
        valueRef.current.value = null;
        if (!disableUnform) {
          formRef.current.setFieldError(fieldName, null);
        }
        e.target.changed = false;
        await handleChange(null);
      } else {
        commitLoading(true);

        let response = [];

        const fixData = selfField.fixedData || fixedData;

        if (fixData) {
          response = fixData.filter(
            d =>
              d[propFieldLabel]
                .toString()
                .toUpperCase()
                .indexOf(inputRef.current.value.toString().toUpperCase()) >= 0
          );
        } else {
          response = await method(searchTextInput);
        }

        switch (response.length) {
          case 0:
            if (!disableUnform && formRef.current) {
              formRef.current.setFieldError(fieldName, t('error.InvalidValue'));
            }
            valueRef.current.value = null;
            inputRef.current.focus();
            await handleChange(null);
            commitLoading(false);
            break;

          case 1:
            if (response[0].optionDisabled === 'Y') {
              if (!disableUnform) {
                formRef.current.setFieldError(
                  fieldName,
                  t('error.InvalidValue')
                );
              }
              valueRef.current.value = null;
              inputRef.current.focus();
              await handleChange(null);
            } else {
              await onSelect(response[0], false);
            }
            commitLoading(false);
            break;

          default:
            setSearchData(response);
            setIsShowSearchViewer(true);
            setSearchText(searchTextInput);
            if (!disableUnform && formRef.current)
              formRef.current.setFieldError(fieldName, t('error.InvalidValue'));
            await handleChange(null);
            break;
        }
      }
    }
  }

  const handlerOnLinkClick = async ({ value }) => {
    await sendEvent({
      settings,
      eventName: 'onlinkclick',
      data: value,
      element: inputRef.current,
      formRef,
      mainFormRef,
      events: selfField.events,
      auxScope,
    });
  };

  async function onLink() {
    if (onLinkClick)
      onLinkClick({
        value: valueRef.current.value,
        label: valueRef.current.value,
        form: formRef && formRef.current ? formRef.current : null,
        formData: formRef && formRef.current ? formRef.current.getData() : {},
        mainForm:
          mainFormRef && mainFormRef.current ? mainFormRef.current : null,
      });

    if (isUserField || isCustomTab)
      await handlerOnLinkClick({ value: inputRef.current.value });
  }

  const hasEvent = (eventName, run) => {
    const events = rest.events || selfField.events || [];

    const event = events.find(e => {
      e.run = e.run || 'before';

      return (
        e.name.toUpperCase() === eventName.toUpperCase() &&
        e.run.toUpperCase() === run.toUpperCase()
      );
    });

    return !!event;
  };

  async function method(props) {
    const clientEvent = hasEvent('onlist', 'before');

    if (clientEvent) {
      const response = await handlerEvent('onlist', props, 'before');
      if (response) return response || [];
    }

    if (selfField.fixedData || fixedData) {
      return selfField.fixedData || fixedData;
    }

    const clientResponse = await clientMethod(props);

    if (!forceSelection) {
      if (props && props !== '') {
        if (!clientResponse.find(res => res[propFieldValue] === props)) {
          clientResponse.unshift({
            value: props,
            label: props,
            _isDraftItem: true,
          });
        }
      }
    }

    return clientResponse;
  }

  function handleRenderItem(data) {
    return (
      <SelectItem
        auxInfo={data.auxInfo || data.AuxInfo || data[propFieldAuxInfo]}
        header={data[propFieldLabel]}
      />
    );
  }

  useImperativeHandle(ref, () => ({
    openSearch: () => {
      if (isMobile) {
        return mobileRef.current.openSearch();
      }
      return setIsShowSearchViewer(true);
    },
    setReadOnly: status => {
      setIsReadOnly(status);
    },
  }));

  useEffect(() => {
    setIsShowLink(showLink);
  }, [showLink]);

  const customEvent = (
    <CustomEvent
      settings={settings}
      selfField={selfField}
      inputRef={inputRef}
      formRef={formRef}
      mainFormRef={mainFormRef}
      auxScope={auxScope}
    />
  );

  try {
    return (
      <>
        <input
          ref={valueRef}
          id={valueFieldName}
          defaultValue={valueDefaultValue || null}
          autoComplete="off"
          hidden
        />

        <FieldContainer
          hidden={isHidden}
          readOnly={isReadOnly}
          labelPosition={labelPosition}
          viewMode={!hidden || isUserField ? viewMode : 'normal'}
          onContextMenu={event => showContextMenu({ event })}
          style={
            isMobile
              ? {
                  ...(selfField && selfField.style ? selfField.style : {}),
                  ...(rest && rest.style ? rest.style : {}),
                }
              : {}
          }
        >
          {label && (
            <Title
              highlight={isUserField && viewMode === 'design'}
              labelPosition={labelPosition}
              labelWidth={
                (selfField && selfField.labelWidth) || labelWidth || 130
              }
            >
              <div className="title">
                <Switch
                  mobile={
                    <div className="title">
                      <div>
                        {fieldTitle}
                        {isRequired && <b>*</b>}
                      </div>
                      <div className="title-icon">{customEvent}</div>
                    </div>
                  }
                  computer={
                    <>
                      <div className="title">
                        <div className="required-field">
                          {fieldTitle}
                          {isRequired && <b>*</b>}
                        </div>
                      </div>
                      <div className="title-icon">
                        {customEvent}
                        {isShowLink &&
                          valueRef &&
                          valueRef.current &&
                          valueRef.current.value &&
                          !valueRef.current._isDraftItem && (
                            <LinkIcon onClick={onLink} />
                          )}
                      </div>
                    </>
                  }
                />
                <HasEventComponent selfField={selfField} viewMode={viewMode} />
              </div>
              {(error || valueError) && (
                <div className="error">{error || valueError}</div>
              )}
            </Title>
          )}

          <Switch
            mobile={
              <>
                <input
                  ref={inputRef}
                  defaultValue={defaultValue}
                  style={{ display: 'none' }}
                  autoComplete="off"
                />
                <MobileView
                  ref={mobileRef}
                  t={t}
                  showLink={
                    isShowLink &&
                    valueRef.current &&
                    valueRef.current.value &&
                    !valueRef.current._isDraftItem
                  }
                  title={fieldTitle}
                  text={mobileText === null ? defaultValue : mobileText}
                  fixedData={selfField.fixedData || fixedData}
                  method={method}
                  renderItem={renderItem || handleRenderItem}
                  readOnly={isReadOnly}
                  {...rest}
                  {...selfField}
                  initialFilterMobileText={initialFilterMobileText}
                  propFieldLabel={propFieldLabel}
                  propFieldValue={propFieldValue}
                  forceSelection={forceSelection}
                  onLink={onLink}
                  onConfirm={async data => {
                    valueRef.current.value = data ? data[propFieldValue] : null;
                    inputRef.current.value = data ? data[propFieldLabel] : null;

                    if (data._isDraftItem) {
                      inputRef.current._isDraftItem = data._isDraftItem;
                      valueRef.current._isDraftItem = data._isDraftItem;
                    }
                    setMobileText(inputRef.current.value || '');
                    await handleChange(data);
                  }}
                />
              </>
            }
            computer={
              <PanelInput
                readOnly={
                  isReadOnly || selfField.disabled || selfField.readOnly
                }
                style={{
                  ...(selfField && selfField.panelInputStyle
                    ? selfField.panelInputStyle
                    : {}),
                  ...(rest && rest.panelInputStyle ? rest.panelInputStyle : {}),
                }}
              >
                <div>
                  <Input
                    ref={inputRef}
                    defaultValue={defaultValue}
                    {...selfField}
                    {...rest}
                    hidden={false}
                    readOnly={isReadOnly}
                    onBlur={async e => {
                      if (!isReadOnly) await onBlur(e);
                    }}
                    autoComplete="off"
                    onKeyDown={e => {
                      switch (e.keyCode) {
                        case 27:
                        case 9:
                        case 13:
                        case 20:
                        case 16:
                        case 17:
                        case 18:
                        case 91:
                        case 93:
                        case 37:
                        case 38:
                        case 39:
                          break;

                        case 40: // Seta para baixo
                        case 115: // F4
                          if (!isReadOnly) {
                            if (e.target.changed) {
                              e.target.changed = false;

                              // eslint-disable-next-line eqeqeq
                              if (e.target.value != '' && !disableUnform)
                                formRef.current.setFieldError(
                                  fieldName,
                                  'Valor inválido'
                                );
                            }

                            setSearchData(null);
                            setSearchText('');
                            setIsShowSearchViewer(true);
                          }
                          break;

                        default:
                          valueRef.current.value = null;
                          e.target.changed = true;
                          break;
                      }
                    }}
                  />
                  {!isReadOnly &&
                    !(selfField.disabled || false) &&
                    (isLoading ? (
                      <LoadIcon>
                        <AiOutlineLoading size={12} color="#aaa" />
                      </LoadIcon>
                    ) : (
                      <SearchIcon
                        onClick={() => {
                          setIsShowSearchViewer(true);
                        }}
                      >
                        <FaBars size={12} color="#aaa" />
                      </SearchIcon>
                    ))}
                </div>
                <Modal isOpened={isShowSearchViewer}>
                  {isShowSearchViewer ? (
                    <Detail
                      t={t}
                      searchData={searchData}
                      method={method}
                      onClose={() => {
                        setIsShowSearchViewer(false);
                        commitLoading(false);
                        inputRef.current.focus();
                      }}
                      onConfirm={onSelect}
                      title={fieldTitle}
                      text={searchText}
                      fixedData={selfField.fixedData || fixedData}
                      renderItem={renderItem || handleRenderItem}
                    />
                  ) : (
                    <></>
                  )}
                </Modal>
              </PanelInput>
            }
          />
        </FieldContainer>

        <UserField
          baseName={baseName}
          fieldName={fieldName}
          formRef={formRef}
          mainFormRef={mainFormRef}
          settings={settings}
          // readOnly={readOnly || readOnlyElement}
          labelWidth={(selfField && selfField.labelWidth) || labelWidth || 130}
          auxScope={auxScope}
        />
      </>
    );
  } catch (e) {
    if (renderized.current) {
      Toast.error(e.message);
    } else {
      Toast.warn(e.message);
    }
    return null;
  }
}

const LinkerComponent = forwardRef(LinkerElement);

LinkerComponent.prototypes = {
  onChange: PropTypes.func,
  renderItem: PropTypes.func,
  onStartLoading: PropTypes.func,
  onStopLoading: PropTypes.func,
  showLink: PropTypes.bool,
  mainFormRef: PropTypes.shape(),
};

LinkerComponent.defaultProps = {
  mainFormRef: {},
  onChange: () => {},
  onLinkClick: () => {},
  showLink: false,
};

export default LinkerComponent;
