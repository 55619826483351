/* eslint-disable func-names */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable new-cap */
/* eslint-disable no-new-func */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useRef,
  createContext,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { isMobile } from '~/easy-components/DeviceDetect';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';

import {
  search,
  selectRow,
  selectSingleRow,
  deselectAllRows,
  selectAllFilteredRows,
  deselectAllFilteredRows,
  updateCellValue,
  deselectRow,
  updateFilterRowValue,
  hideColumn,
  showColumn,
  openRowEditors,
  closeRowEditors,
  setFocused,
  selectAllRows,
} from 'ka-table/actionCreators';
import { SortingMode, FilteringMode } from 'ka-table/enums';

import { Decimal } from 'decimal.js';
import { kaPropsUtils } from 'ka-table/utils';
import { getValueByColumn } from 'ka-table/Utils/DataUtils';
import { kaReducer, Table } from 'ka-table';
import {
  SwipeableList,
  SwipeableListItem,
} from '@sandstreamdev/react-swipeable-list';

import PromptModal from '~/easy-components/PromptModal';
import Form from '~/easy-components/Form';
import Toast from '~/easy-components/Toast';
import useCompany from '~/hooks/useCompany';
import useComponentState from '~/hooks/useComponentState';
import handleLoadDataEvent from './exportsHooks/useOnLoadDataEvent';

import CompareRender from '../Helpers/compareRender';
import {
  Container,
  Content,
  Toolbar,
  FooterBar,
  SearchMobileInput,
  Resume,
  AddLineButton,
  PDFButton,
  CSVLinkWrapper,
} from './styles';
import DateFilter from './Filters/DateFilter';
import TextFilter from './Filters/TextFilter';
import NumberFilter from './Filters/NumberFilter';
import SwipeButton from './SwipeButton';
import SwipeModalButtons from './SwipeModalButtons';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import Card from './Card';
import 'ka-table/style.css';
import { Container as ResponsiveContainer } from './responsiveContainer';
import dynamicFunction from './dynamicFunction';
import ModalDetail from './ModalDetail';
import CellContent from './Components/CellContent';
import getFormatCellText from './Helpers/getFormatCellText';
import {
  createAsyncFunctionByString,
  createSyncFunctionByString,
} from '../AsyncFunctionString';

import GroupBy from './GroupBy';
import SelectionCell from './CustomCells/SelectionCell';
import validateAndSelectRow from './CustomActions/validateAndSelectRow';
import setCellReadOnly, {
  SET_CELL_READ_ONLY_TYPE,
} from './CustomActions/setCellReadOnly';
import setColumnReadOnly, {
  SET_COLUMN_READ_ONLY_TYPE,
} from './CustomActions/setColumnReadOnly';
import CellSummary from './Components/CellSummary';
import MenuPopUp from './Components/MenuPopUp';
import ImportationTool from './Components/ImportationTool';
import FullScreenButton from './Components/FullScreenButton';
import DefaultEmptyContainer from './Components/DefaultEmptyContainer';
import Icon from '../Icon';
import TreatError from '../TreatError';
import isValidJson from '../Helpers/isValidJson';
import Header from './Components/Header';
import FlexSpace from '../FlexSpace';
import FooterButton from './Components/FooterButton';
import CSVExportButton from './Components/CSVExportButton';
import MobileCardJSX from './MobileCardJSX';
import CellGroup from './Components/CellGroup';

export const useOnLoadDataEvent = handleLoadDataEvent;

function normalizeObject(obj) {
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in obj) {
    if (key === 'EasyLineId') {
      delete obj.EasyLineId;
    }
    try {
      const parsedValue = JSON.parse(obj[key]);
      obj[key] = parsedValue.value || parsedValue;

      if (parsedValue.value) {
        obj[`_${key}`] = parsedValue.label;
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  return obj;
}

function normalizeArray(arr) {
  arr.forEach(obj => normalizeObject(obj));
  return arr;
}

function normalize(data) {
  if (Array.isArray(data)) {
    return normalizeArray(data);
  }
  return normalizeObject(data);
}

/**
 * @callback GetSwipeButtonsSettings
 * @param {{rowProps: React.PropsWithChildren<import('ka-table/props').IDataRowProps}} params
 * @returns {{
 * swipeLeft: any
 * swipeRight: any
 * leftButtons: any[]
 * rightButtons: any[]
 * }}
 */

/**
 * @typedef {Omit<import('ka-table').ITableProps, "columns">} TablePropsWithoutColumns
 */

/**
 * @typedef {import('ka-table/models').Column & { settings: any}} EasyColumn
 */

/**
 * @typedef {TablePropsWithoutColumns & { columns: EasyColumn[]}} EasyTableProps
 */

/**
 * @typedef {Object} PageContext
 * @property {GetSwipeButtonsSettings} getSwipeButtonsSettings
 * @property {any} modalDetailButtonsRef
 * @property {any} promptRef
 * @property {any} customModalRef
 * @property {any} dispatch
 * @property {any} paramsModal
 * @property {any} getSelectedData
 * @property {any} showLoading
 * @property {any} auxScope
 * @property {any} dispatchOnUploadInLine
 * @property {any} dispatchOnSuccessUploadInLine
 * @property {any} dispatchOnValidateUploadInLine
 * @property {any} dispatchOnDeleteUploadInLine
 * @property {any} dispatchOnSuccessDeleteUploadInLine
 * @property {any} dispatchOnOpenInputLinkerInLine
 * @property {any} onGetDownloadToken
 * @property {any} setRowFieldValue
 * @property {any} refreshTable
 * @property {any} setRowFieldReadOnly
 * @property {any} removeLine
 * @property {any} settings
 * @property {any} dispatchOnContextMenuEvent
 * @property {any} contextMenuOptions
 * @property {any} transformHandlerInOnClick
 * @property {any} isResponsible
 * @property {any} getCellStyleFunction
 * @property {any} items
 * @property {any} reportSettings
 * @property {EasyTableProps} tableProps
 * @property {any} rowKeyField
 * @property {any} getCellContentProps
 */

/** @type {React.Context<PageContext>} */
export const PageContext = createContext();

/**
 * @typedef {import('react').MutableRefObject<import('@unform/core').FormHandles>} InputsFormRef
 */

/**
 * @typedef {Object} ComponentState
 * @property {import('ka-table').ITableProps} tableProps
 * @property {string | null} mobileSelectedRowKeyValue
 * @property {boolean} detailModalIsOpen
 * @property {Array<any>} rawData
 * @property {number} currentPage
 */

const JsonTable = (
  {
    title,
    fileName,
    subTitle,
    data,
    columns: columnsProps,
    pdfFontSize = 7,
    pdfOrientation = 'portrait',
    groups = [],
    settings = {},
    reportSettings,
    onRefresh,
    showLoading,
    onSelect,
    hideToolbar = false,
    hasFilter = true,
    isMultSelection = true,
    paramsModal,
    appDispatch = () => {},
    onEmpty = () => null,
    auxScope = null,
    onUploadInLine = () => ({}),
    onSuccessUploadInLine = () => ({}),
    onValidateUploadInLine = () => ({}),
    onDeleteUploadInLine = () => {},
    onSuccessDeleteUploadInLine = () => {},
    onGetDownloadToken = () => {},
    onOpenInputLinkerInLine = () => {},
    onChange = () => {},
    onValidadeDelete,
    t = () => {},
    isReadOnly = false,
    header = null,
  },
  ref
) => {
  if (
    reportSettings.globalFunctions &&
    typeof reportSettings.globalFunctions === 'object'
  ) {
    Object.keys(reportSettings.globalFunctions).forEach(function(prop) {
      if (typeof reportSettings.globalFunctions[prop] === 'string') {
        const dynFunction = createAsyncFunctionByString({
          functionString: reportSettings.globalFunctions[prop],
          inputParamName: 'param',
        });
        reportSettings.globalFunctions[prop] = dynFunction;
      }
    });
  }

  const { dateToString, stringToDate, companySettings } = useCompany();
  const userInfo = useSelector(({ user }) => user);

  const modalSwipeButtonsRef = useRef();
  const modalDetailButtonsRef = useRef();
  const promptRef = useRef();
  const customModalRef = settings.displayModalRef;
  const formRef = useRef();
  /** @type {InputsFormRef} */
  // const inputsFormRef = useRef();
  // const focusedRef = useRef();
  const rowDataRef = useRef();
  const contextMenuRef = useRef();
  const containerRef = useRef();

  /** @type {ReturnType<typeof useComponentState<ComponentState>>} */
  const [
    componentState,
    setComponentState,
    updateComponent,
    getComponentState,
  ] = useComponentState({
    /**
     * Observações ao adicionar uma nova propriedade no estado:
     *  - Se a sua propriedade depende de uma prop do componente, como por exemplo a propriedade
     * `filteringMode`, replique sua declaração na função `reloadStateThatReliesOnProps`
     */
    tableProps: {
      columns: [],
      data: [],
    },
    mobileSelectedRowKeyValue: null,
    detailModalIsOpen: false,
    rawData: [],
    currentPage: 0,
  });

  const {
    tableProps,
    mobileSelectedRowKeyValue,
    detailModalIsOpen,
    rawData,
    currentPage,
  } = componentState;

  const pageSize = reportSettings.pageSize || 100;

  const columns = useMemo(() => {
    // TRADUÇÃO
    const translatedColumns = columnsProps.map(column => {
      const columnSettings = (column && column.settings) || {};
      let columnTitle = columnSettings.title || column.title;

      if (columnSettings.translateRoute) {
        const newTitleTranslated = t(
          columnSettings.translateKey || columnSettings.name,
          columnSettings.translateRoute
        );
        columnTitle = newTitleTranslated;
      }
      return {
        ...(column || {}),
        title: columnTitle,
      };
    });

    const devicePropHidden = isMobile ? 'hiddenMobile' : 'hiddenDesktop';

    const deviceColumns = translatedColumns.map(col => {
      if (!col.settings) {
        col.settings = {};
      }

      if (!col.settings[devicePropHidden]) {
        col.settings[devicePropHidden] = false;
      }

      return { ...col, visible: !col.settings[devicePropHidden] };
    });

    let finalColumns = deviceColumns;

    if (reportSettings.columnsOrder) {
      const orderedColumns = [];

      for (
        let columnIndex = 0;
        columnIndex < reportSettings.columnsOrder.length;
        columnIndex += 1
      ) {
        const column = reportSettings.columnsOrder[columnIndex];

        const columnToPush = finalColumns.find(c => c.key === column);

        if (columnToPush) {
          orderedColumns.push(columnToPush);
        }
      }

      const orderedKeys = orderedColumns.map(c => c.key);

      const ignoredColumns = finalColumns.filter(
        c => !orderedKeys.includes(c.key)
      );

      for (
        let columnIndex = 0;
        columnIndex < ignoredColumns.length;
        columnIndex++
      ) {
        const column = ignoredColumns[columnIndex];

        orderedColumns.push(column);
      }

      finalColumns = orderedColumns;
    }

    return finalColumns;
  }, [columnsProps, reportSettings.columnsOrder, t]);

  const SelectionHeader = ({ dispatch, areAllRowsSelected }) => {
    return (
      <SelectionCell
        value="S"
        isSelectedRow={areAllRowsSelected}
        onClick={() => {
          if (!areAllRowsSelected) {
            dispatch(selectAllFilteredRows());
          } else {
            dispatch(deselectAllFilteredRows());
          }
        }}
      />
    );
  };

  const resultGroups = useMemo(() => {
    let groupsToUse;

    if (groups.length > 0) {
      groupsToUse = groups;
    } else if (reportSettings.groups && Array.isArray(reportSettings.groups)) {
      groupsToUse = reportSettings.groups;
    } else {
      groupsToUse = [];
    }

    const formatEachGroup = group => {
      switch (typeof group) {
        case 'object':
          return group;
        case 'string':
        default:
          return {
            columnKey: group,
          };
      }
    };

    const invalidGroups = groupsToUse.filter(group => {
      let columnKey;

      switch (typeof group) {
        case 'object':
          columnKey = group.columnKey;
          break;
        default:
          columnKey = group;
          break;
      }

      return (
        tableProps.columns.length &&
        tableProps.columns.findIndex(col => col.key === columnKey) === -1
      );
    });

    if (invalidGroups.length > 0) {
      const [group] = invalidGroups;
      switch (typeof group) {
        case 'object':
          Toast.error(
            `Table settings error - Invalid group in table ${title ||
              ''}, Expected prop columnKey in object to be found in columns array.`
          );
          break;
        default:
          Toast.error(
            `Table settings error - Invalid group in table ${title ||
              ''}, ${group} expected to be found in columns array.`
          );
          break;
      }

      return [];
    }

    return groupsToUse && Array.isArray(groupsToUse) && groupsToUse.length > 0
      ? groupsToUse.map(formatEachGroup)
      : null;
  }, [groups, reportSettings.groups, tableProps.columns, title]);

  const cellDensityPadding = useMemo(() => {
    if (!reportSettings.cellDensity) return '8px 20px';

    switch (reportSettings.cellDensity) {
      case 'default':
        return '8px 20px';

      case 'compact':
        return '4px 10px';

      case 'minimum':
        return '2px 5px';
    }
  }, [reportSettings.cellDensity]);

  const getPageData = (pageIndex, rows) => {
    const lastIndex = pageIndex * pageSize + pageSize;

    const nextPageIndex = lastIndex < rows.length ? pageIndex + 1 : -1;

    return {
      data: rows.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize),
      nextPageIndex,
    };
  };

  /**
   * Quando o componente pai renderiza a tabela de novo com mudanças nas props o JsonTable deveria
   * atualizar seu estado para as props mais recentes. Essa função faz isso e ela é chamada no useEffect logo abaixo.
   */
  const reloadStateThatReliesOnProps = () => {
    if (JSON.stringify(reportSettings) !== '{}') {
      setComponentState('rawData', data);

      let pageData = data;

      if (reportSettings.infinityScroll && !reportSettings.newLine) {
        const result = getPageData(0, data);
        setComponentState('infinity', result.nextPageIndex);
        pageData = result.data;

        setComponentState('currentPage', result.nextPageIndex);
      }

      setComponentState('tableProps', oldState => {
        const updatedData = pageData.map(row => {
          const inputLinkerAsObject = Object.fromEntries(
            Object.entries(row).map(([key, value]) => {
              const column = columns.find(c => c.key === key);

              if (!column) return [key, value];

              const { fixedData = [] } = column.settings || { fixedData: [] };

              if (column.settings && column.settings.type !== 'inputLinker') {
                return [key, value];
              }

              if (!value) return [key, value];

              if (isValidJson(value) && JSON.parse(value).label) {
                return [key, value];
              }

              if (value.toString().includes('+')) {
                const [id, label] = value.split('+');
                return [key, JSON.stringify({ value: id, label })];
              }

              if (fixedData.length) {
                const { label, value: id } =
                  fixedData.find(({ value: fdID }) => fdID === value) || {};

                return [key, JSON.stringify({ value: id, label })];
              }

              return [key, value];
            })
          );

          const dateWithoutTimezone = Object.fromEntries(
            Object.entries(inputLinkerAsObject).map(([key, value]) => {
              const column = columns.find(c => c.key === key);

              if (!column) return [key, value];

              if (
                column.settings &&
                column.settings.type !== 'date' &&
                column.settings.type !== 'inputDate'
              ) {
                return [key, value];
              }

              if (!value) return [key, value];

              return [key, value.replace('Z', '')];
            })
          );

          return dateWithoutTimezone;
        });

        let rowKeyField = oldState.rowKeyField;

        if (!rowKeyField || rowKeyField === '_key') {
          rowKeyField = reportSettings.rowKeyField || '_key';
        }

        const readOnlyColumns = isReadOnly ? columns.map(col => col.key) : [];

        const selectionKeyField =
          reportSettings.selectionKeyField || 'Selection';

        const selectedKeyFields = updatedData
          .map(el => (el[selectionKeyField] === 'Y' ? el[rowKeyField] : ''))
          .filter(String);

        const selectedRows = (
          reportSettings.selectedRows ||
          selectedKeyFields ||
          []
        ).map(String);

        return {
          ...oldState,
          readOnlyColumns,
          columns,
          data: updatedData,
          groups: resultGroups,
          virtualScrolling: null,
          cellDensity: reportSettings.cellDensity || 'default',
          cellDensityPadding,
          sortingMode: SortingMode.Single,
          readOnlyInputs: reportSettings.readOnlyInputs || [],
          filteringMode:
            (hasFilter || reportSettings.hasFilter !== false) &&
            !reportSettings.infinityScroll
              ? FilteringMode.FilterRow
              : FilteringMode.None,
          rowKeyField,
          selectedRows,
          filter: ({ column }) => {
            if (column && column.settings) {
              switch (column.settings.dataType) {
                case 'date':
                  return (value, filterRowValue) => {
                    try {
                      const dateValue = dateToString(stringToDate(value));
                      const filterDateValue = filterRowValue;
                      const dateIsEqual = dateValue.includes(filterDateValue);

                      return dateIsEqual;
                    } catch (error) {
                      return false;
                    }
                  };

                case 'number':
                  return (value, filterRowValue) => {
                    try {
                      const filterValue = new Decimal(filterRowValue);
                      return filterValue.equals(value);
                    } catch (error) {
                      return false;
                    }
                  };

                default:
                  break;
              }
            }
          },
        };
      });

      updateComponent();
    }
  };

  const setReadOnlyTrue = ({ prevState, columnKey }) => {
    if (prevState.readOnlyColumns) {
      prevState.readOnlyColumns.push(columnKey);
      return { ...prevState };
    }

    prevState.readOnlyColumns = [columnKey];
    return { ...prevState };
  };

  const setReadOnlyFalse = ({ prevState, columnKey }) => {
    if (prevState.readOnlyColumns) {
      const newReadOnlyColumns = prevState.readOnlyColumns.filter(
        column => column !== columnKey
      );

      prevState.readOnlyColumns = newReadOnlyColumns;
      return { ...prevState };
    }

    return { ...prevState };
  };

  const setColumnReadOnlyByStatus = ({
    prevState,
    columnKey,
    isReadOnly: isColumnReadOnly,
  }) => {
    if (isColumnReadOnly === true) {
      return setReadOnlyTrue({ prevState, columnKey });
    }
    return setReadOnlyFalse({ prevState, columnKey });
  };

  const setRowFieldValue = useCallback(
    (rowKeyValue, columnKey, newValue, forceUpdate = true) => {
      if (newValue instanceof Date) {
        newValue = newValue.toISOString().replace('Z', '');
      }

      if (
        tableProps.columns.find(column => column && column.key === columnKey)
      ) {
        setComponentState(
          'tableProps',
          prevState =>
            kaReducer(
              prevState,
              updateCellValue(rowKeyValue, columnKey, newValue)
            ),
          forceUpdate
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableProps.columns]
  );

  const refreshTable = useCallback(() => {
    updateComponent();
  }, [updateComponent]);

  const makeLineFallback = () => {
    const { columns: tablePropsColumns = [] } = tableProps;
    const entries = tablePropsColumns.map(column => {
      /**
       * Caso algum dia não seja null o fallback, temos um dicionário pronto
       */
      // const dictionary = {
      //   string: '',
      //   object: {},
      //   number: 0,
      //   date: new Date().toISOString(),
      //   boolean: false,
      // };

      return [column.key, null];
    });

    const defaultNewLineFallback = Object.fromEntries(entries);

    if (tableProps.rowKeyField !== 'EasyLineId') {
      defaultNewLineFallback[tableProps.rowKeyField] =
        -tableProps.data.length - 1;
    }

    return defaultNewLineFallback;
  };

  const getNewLineFromString = async (string, currentData) => {
    const newLine = await dynamicFunction({
      functionString: string,
      settings,
      dispatch: appDispatch,
      promptRef,
      params: {
        ...auxScope,
        data: currentData || tableProps.data,
        rawData,
      },
    });

    return newLine;
  };

  const getDefaultNewLine = async currentData => {
    const lineFallback = makeLineFallback();
    if (!reportSettings.newLineDefaultData) {
      return lineFallback;
    }

    if (typeof reportSettings.newLineDefaultData === 'object') {
      return {
        ...lineFallback,
        ...reportSettings.newLineDefaultData,
      };
    }

    const line = await getNewLineFromString(
      reportSettings.newLineDefaultData,
      currentData
    );
    return { ...lineFallback, ...line };
  };

  const addEasyLineIdColumn = useCallback(() => {
    // Verifica se a coluna EasyLineId já existe
    // se não existir, adiciona a coluna

    setComponentState(
      'tableProps',
      prevState => {
        const easyLineIdColumn = prevState.columns.find(
          column => column.key === 'EasyLineId'
        );

        if (easyLineIdColumn) return prevState;

        return {
          ...prevState,
          columns: [
            {
              key: 'EasyLineId',
              dataType: 'number',
              title: '#',
              visible: true,
              style: { textAlign: 'left' },
              settings: {
                type: 'integer',
                readOnly: true,
              },
            },
            ...prevState.columns,
          ],
          rowKeyField: 'EasyLineId',
          readOnlyColumns: [...prevState.readOnlyColumns, 'EasyLineId'],
          data: prevState.data.map((line, index) => ({
            ...line,
            EasyLineId: index + 1,
          })),
        };
      },
      true
    );
  }, [setComponentState]);

  const getSelectedData = () => {
    const updatedState = getComponentState();

    const { tableProps: tProps } = updatedState;

    const { selectedRows: sRows, data: updatedData } = tProps;

    const selected = updatedData.filter(line => {
      return sRows
        .map(sel => sel.toString())
        .includes(line[updatedState.tableProps.rowKeyField].toString());
    });
    return selected;
  };
  /**
   * @param {ReturnType<typeof validateAndSelectRow> | {type: import('ka-table/enums').ActionType}} action
   * @param {boolean} [forceUpdate=true]
   */
  const dispatch = async (action, forceUpdate = true) => {
    switch (action.type) {
      case 'validateAndSelectRow': {
        const { callingFromCell, forceSelection } = action.payload;
        const selectionColumns = columns.filter(
          c => c.settings.type === 'selection'
        );
        const selectionColumnExists = !!selectionColumns.length;

        const selectionColumn = selectionColumnExists
          ? selectionColumns[0]
          : {};

        let allowCheck = true;

        const { rowData, value, isSelectedRow, rowKeyValue } = callingFromCell;

        if (selectionColumn.settings && selectionColumn.settings.onValidate) {
          const isValid = await dynamicFunction({
            functionString: selectionColumn.settings.onValidate,
            dispatch: appDispatch,
            settings,
            promptRef,
            params: {
              isChecking: forceSelection || !isSelectedRow,
              column: selectionColumn,
              line: rowData,
              value,
              rowKeyValue,
              refresh: onRefresh,
              getSelectedData,
              reportSettings,
              setFieldValue: (columnKey, newValue, fu) => {
                setRowFieldValue(rowKeyValue, columnKey, newValue, fu);
              },
              refreshTable,
              items: tableProps.data,
              rawItems: rawData,
              ...auxScope,
            },
          });

          if (isValid === false) {
            allowCheck = false;
          }
        }

        if (allowCheck) {
          if (isSelectedRow && !forceSelection) {
            dispatch(deselectRow(rowKeyValue), forceUpdate);
          } else {
            dispatch(selectRow(rowKeyValue), forceUpdate);
          }
        } else {
          dispatch(deselectRow(rowKeyValue), forceUpdate);
        }

        break;
      }

      case SET_COLUMN_READ_ONLY_TYPE: {
        const { columnKey, isReadOnly: isColumnReadOnly } = action.payload;

        setComponentState(
          'tableProps',
          prevState => {
            return setColumnReadOnlyByStatus({
              prevState,
              columnKey,
              isReadOnly: isColumnReadOnly,
            });
          },
          forceUpdate
        );

        break;
      }

      case SET_CELL_READ_ONLY_TYPE: {
        const {
          columnKey,
          rowKeyValue,
          isReadOnly: isColumnReadOnly,
        } = action.payload;

        const hasReadOnly = tableProps.readOnlyInputs.some(
          column =>
            column.columnKey === columnKey && column.rowKeyValue === rowKeyValue
        );

        if (isColumnReadOnly && !hasReadOnly) {
          setComponentState(
            'tableProps',
            prevState => {
              const newReadOnlyInputs = []
                .concat(prevState.readOnlyInputs)
                .concat([
                  {
                    columnKey,
                    rowKeyValue,
                  },
                ]);

              return { ...prevState, readOnlyInputs: newReadOnlyInputs };
            },
            forceUpdate
          );
        } else if (!isColumnReadOnly && hasReadOnly) {
          setComponentState(
            'tableProps',
            prevState => {
              const newReadOnlyInputs = prevState.readOnlyInputs.filter(
                column =>
                  column.columnKey !== columnKey ||
                  column.rowKeyValue !== rowKeyValue
              );

              return { ...prevState, readOnlyInputs: newReadOnlyInputs };
            },
            forceUpdate
          );
        }

        break;
      }

      case 'OpenRowEditors': {
        const editableCells = tableProps.editableCells || [];

        const openedRow =
          editableCells.length > 0
            ? tableProps.editableCells[0].rowKeyValue
            : null;

        if (openedRow) {
          dispatch(closeRowEditors(openedRow));
        }

        setComponentState(
          'tableProps',
          prevState => kaReducer(prevState, action),
          true
        );

        const closeAllEditorsWhenPressEscape = e => {
          if (e.key !== 'Escape') return;

          setComponentState(
            'tableProps',
            prevState => {
              return {
                ...prevState,
                editableCells: [],
                focused: null,
              };
            },
            true
          );

          document.removeEventListener(
            'keydown',
            closeAllEditorsWhenPressEscape
          );
        };

        document.addEventListener('keydown', closeAllEditorsWhenPressEscape);
        break;
      }

      case 'SetFocused': {
        if (
          action.focused &&
          action.focused.cell &&
          action.focused.cell.rowKeyValue
        ) {
          dispatch(openRowEditors(action.focused.cell.rowKeyValue), false);
        }

        setComponentState('tableProps', prevState =>
          kaReducer(prevState, action)
        );

        break;
      }

      case 'loadMoreData':
        if (currentPage !== -1) {
          showLoading(true);
          const result = getPageData(currentPage, rawData);
          setComponentState('currentPage', result.nextPageIndex);

          setComponentState('tableProps', prevState => {
            return {
              ...prevState,
              data: [...(tableProps.data || []), ...result.data],
            };
          });

          updateComponent();
          showLoading(false);
        }
        break;

      case 'SelectRow':
      case 'DeselectRow': {
        setComponentState(
          'tableProps',
          prevState => kaReducer(prevState, action),
          forceUpdate
        );

        const { tableProps: tProps } = getComponentState();

        const { rowKeyValue } = action;

        const rowData = tProps.data.find(
          line => line[tProps.rowKeyField] === rowKeyValue
        );

        const selectionColumns = columns.filter(
          c => c.settings.type === 'selection'
        );
        const selectionColumnExists = !!selectionColumns.length;

        const selectionColumn = selectionColumnExists
          ? selectionColumns[0]
          : {};

        if (!selectionColumn.settings.onChange) return;

        await dynamicFunction({
          functionString: selectionColumn.settings.onChange,
          dispatch: appDispatch,
          settings,
          promptRef,
          params: {
            column: selectionColumn,
            line: rowData,
            value: tProps.selectedRows.includes(rowKeyValue),
            rowKeyValue,
            refresh: onRefresh,
            getSelectedData,
            reportSettings,
            setFieldValue: (columnKey, newValue, fu) => {
              setRowFieldValue(rowKeyValue, columnKey, newValue, fu);
            },
            refreshTable,
            ...auxScope,
          },
        });

        break;
      }

      default: {
        setComponentState(
          'tableProps',
          prevState => kaReducer(prevState, action),
          forceUpdate
        );
        break;
      }
    }
  };

  const addNewLine = async currentData => {
    if (!reportSettings.newLine) return;

    showLoading(true);

    try {
      if (reportSettings.shouldAddLine) {
        const shouldAddNewLine = await dynamicFunction({
          functionString: reportSettings.shouldAddLine,
          dispatch: appDispatch,
          settings,
          promptRef,
          params: {
            items: currentData || tableProps.data,
            rawItems: rawData,
            lastLine: (currentData || tableProps.data)[
              tableProps.data.length - 1
            ],
          },
        });

        if (!shouldAddNewLine) return;
      }
      const defaultNewLine = await getDefaultNewLine(currentData);

      setComponentState('tableProps', prevState => {
        const newData = [...prevState.data];

        defaultNewLine.EasyLineId = tableProps.data.length + 1;

        let addMethod = 'push';

        if (reportSettings.newLine === 'before') {
          addMethod = 'unshift';
        }

        newData[addMethod](defaultNewLine);
        return {
          ...prevState,
          data: newData,
        };
      });

      dispatch(openRowEditors(defaultNewLine.EasyLineId), true);
    } catch (error) {
      TreatError.showError(error);
    } finally {
      showLoading(false);
    }
  };

  const setRowFieldReadOnly = useCallback(
    (rowKeyValue, columnKey, isColumnReadOnly) => {
      dispatch(setCellReadOnly(rowKeyValue, columnKey, isColumnReadOnly));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableProps.readOnlyInputs]
  );

  const clearFilter = columnKey => {
    dispatch(updateFilterRowValue(columnKey, ''));
  };

  const clearAllFilters = () => {
    tableProps.columns.forEach(column => {
      dispatch(updateFilterRowValue(column.key, ''));
    });
  };

  const clickOnAddLine = () => {
    const firstInputColumn = tableProps.columns.find(column => {
      if (!column.settings) {
        return false;
      }

      if (!column.settings.type.startsWith('input')) {
        return false;
      }

      if (!column.visible) {
        return false;
      }

      return true;
    });

    if (firstInputColumn) {
      dispatch(
        setFocused({
          cell: {
            rowKeyValue: tableProps.data.length + 1,
            columnKey: firstInputColumn.key,
          },
        }),
        false
      );
    }

    addNewLine();
  };

  useImperativeHandle(ref, () => {
    return {
      changeData: (newData, forceUpdate = true) => {
        setComponentState(
          'tableProps',
          { ...componentState.tableProps, data: newData },
          forceUpdate
        );
      },
      changeTableProps: props => {
        setComponentState('tableProps', props, true);
      },
      getSelectedData,
      clearSelections: () => {
        dispatch(deselectAllRows());
      },
      getData: () => {
        return tableProps.data;
      },
      getNormalizedData: () => {
        const normalizedData = normalize(
          JSON.parse(JSON.stringify(tableProps.data))
        );
        return normalizedData;
      },
      selectRow: rowKeyValue => {
        const updatedState = getComponentState();

        const newState = kaReducer(
          updatedState.tableProps,
          selectRow(rowKeyValue)
        );

        setComponentState('tableProps', newState, true);
      },
      deselectRow: rowKeyValue => {
        const updatedState = getComponentState();

        setComponentState(
          'tableProps',
          kaReducer(updatedState.tableProps, deselectRow(rowKeyValue)),
          true
        );
      },
      setColumnHidden: (columnName, status) => {
        if (status === true) {
          dispatch(hideColumn(columnName));
        } else {
          dispatch(showColumn(columnName));
        }
      },
      setColumnReadOnly: (columnName, status) => {
        dispatch(setColumnReadOnly(columnName, status));
      },
      clearFilter,
      clearAllFilters,
      clickOnAddLine,
    };
  });

  /**
   * @param {Object} params
   * @param {"label" | "value"} params.linkerMode
   * @returns {any[]}
   */
  const getVisibleData = useCallback(
    ({ linkerMode = 'label' }) => {
      return tableProps.data.map(row => {
        const rowEntries = Object.entries(row);

        const entriesWithUnifiedLinkers = rowEntries.map(entry => {
          const [colKey, value] = entry;

          const column = tableProps.columns.find(c => c.key === colKey);

          if (!column) return entry;

          if (!column.settings) return entry;

          if (!column.settings.type) return entry;

          if (column.settings.type !== 'inputLinker') return entry;

          if (typeof value !== 'string') return entry;

          if (value.includes('+')) {
            const parts = value.split('+');
            const part = linkerMode === 'label' ? 1 : 0;

            return [colKey, parts[part]];
          }

          if (isValidJson(value) && typeof JSON.parse(value) === 'object') {
            const parsedJson = JSON.parse(value);

            const returnValue = parsedJson[linkerMode];

            return [colKey, returnValue];
          }

          return entry;
        });

        return Object.fromEntries(entriesWithUnifiedLinkers);
      });
    },
    [tableProps.columns, tableProps.data]
  );

  const cardTemplateFields = useMemo(() => {
    if (reportSettings.mobileCardTemplate) {
      let fields = reportSettings.mobileCardTemplate.match(/{[^}]+}/g);

      if (fields) {
        fields = fields.map(field => {
          const prop = field
            .split('{')
            .join('')
            .split('}')
            .join('');

          const column = columns.find(col => col.key === prop) || {};

          if (!column.settings) {
            column.settings = {};
          }

          const formatter = getFormatCellText({ column, dateToString });

          return {
            prop,
            column,
            formatter,
          };
        });
      }
      return fields || [];
    }
    return null;
  }, [columns, dateToString, reportSettings.mobileCardTemplate]);

  const getRowColor = useMemo(() => {
    if (reportSettings.rowColor) {
      const dynamic = createSyncFunctionByString({
        functionString: reportSettings.rowColor,
      });
      return dynamic;
    }
    return null;
  }, [reportSettings.rowColor]);

  const getCellStyleFunction = useCallback(stringFunction => {
    if (stringFunction) {
      const dynamic = createSyncFunctionByString({
        functionString: stringFunction,
      });
      return dynamic;
    }
    return null;
  }, []);

  const groupTitle = useMemo(() => {
    if (!tableProps.groups || tableProps.groups.length === 0) return null;

    if (!tableProps.columns || tableProps.columns.length === 0) return null;

    const groupedByColumn = tableProps.columns.find(
      c => c.key === tableProps.groups[0].columnKey
    );

    if (!groupedByColumn) return null;

    return groupedByColumn.title;
  }, [tableProps.columns, tableProps.groups]);

  const exportClick = orientation => {
    const doc = new jsPDF(orientation);

    const { pdfColumns: settingsPdfColumns = [] } = reportSettings;

    const allColumns = columns.map(c => c.settings && c.settings.name);

    const pdfColumns = settingsPdfColumns.length
      ? settingsPdfColumns
      : allColumns;

    const notExports = ['button', 'selection'];

    const activeColumns = c =>
      c.settings &&
      !notExports.includes(c.settings.type) &&
      pdfColumns.includes(c.settings.name);

    const head = [
      columns.filter(activeColumns).map(c => {
        return c.title;
      }),
    ];

    const getDataFromColumn = tableLine => {
      return columns.filter(activeColumns).map(columnSetting => {
        let formated = getValueByColumn(tableLine, columnSetting);

        const { settings: settingsInsideColumn = {} } = columnSetting;

        const { type = 'text' } = settingsInsideColumn;

        const formatedDate = new Date(formated);

        if (type === 'date' && formatedDate !== 'Invalid Date') {
          formated = formatedDate.toLocaleDateString();
        }

        return formated;
      });
    };

    // const { data: tableData = [] } = tableProps;

    const tableData = getVisibleData({ linkerMode: 'label' });

    const body = tableData.map(getDataFromColumn);

    doc.setFontSize(12);
    doc.text(title, 10, 10);

    doc.setFontSize(8);
    doc.setTextColor('#777');
    doc.text(subTitle || '', 10, 15);

    doc.setFontSize(7);
    doc.setTextColor('#777');
    const date = new Date();
    doc.text(
      dateToString(date, `${companySettings.DateFormat} HH:mm`),
      10,
      18.5
    );

    doc.autoTable({
      styles: { fontSize: pdfFontSize },
      margin: {
        top: 22,
        left: 10,
        right: 10,
        bottom: 10,
      },
      headStyles: {
        fillColor: '#F1F5F7',
        textColor: '#747D86',
      },
      alternateRowStyles: { fillColor: '#F9FBFC' },
      head,
      body,
    });

    doc.save(`${fileName}.pdf`);
  };

  const mountSelectedRows = ({ rowKeyValuesToDeleted = [] }) => {
    const newSelectedRows = [];

    tableProps.selectedRows.forEach(selectedRow => {
      if (!rowKeyValuesToDeleted.includes(selectedRow)) {
        rowKeyValuesToDeleted.forEach(rowKeyValueDelete => {
          const itemToDeleteIdx = tableProps.data.findIndex(
            d => d[tableProps.rowKeyField] === rowKeyValueDelete
          );

          const idx = tableProps.data.findIndex(
            d => d[tableProps.rowKeyField] === selectedRow
          );

          if (idx > itemToDeleteIdx) {
            newSelectedRows.push(
              tableProps.data[idx - 1][tableProps.rowKeyField]
            );
          } else {
            newSelectedRows.push(tableProps.data[idx][tableProps.rowKeyField]);
          }
        });
      }
    });

    return newSelectedRows;
  };

  const deleteRows = listKeyValues => {
    const { data: tableData } = tableProps;

    const newData = tableData.filter(
      d => !listKeyValues.includes(d[tableProps.rowKeyField])
    );

    const newDataWithNewKeys = newData.map((d, index) => {
      return {
        ...d,
        EasyLineId: index + 1,
      };
    });

    const newSelectedRows = mountSelectedRows({
      rowKeyValuesToDeleted: listKeyValues,
    });

    setComponentState(
      'tableProps',
      oldProps => ({
        ...oldProps,
        data: newDataWithNewKeys,
        selectedRows: newSelectedRows,
      }),
      true
    );
  };

  const isAllowDelete = ({ tableData, rowKeyValue, row }) => {
    if (onValidadeDelete) {
      const normalizedData = normalize(JSON.parse(JSON.stringify(tableData)));

      const normalizedRow = normalize(JSON.parse(JSON.stringify(row)));

      const response = onValidadeDelete({
        data: normalizedData,
        rowKeyValue,
        row: normalizedRow,
      });

      return response === undefined ? true : response;
    }
    return true;
  };

  // Removes a line from the table
  // and recalculates the id of the lines
  const deleteRow = async rowKeyValue => {
    const { data: tableData } = tableProps;

    const row = tableData.find(d => d[tableProps.rowKeyField] === rowKeyValue);

    const response = isAllowDelete({ tableData, rowKeyValue, row });

    if (response) {
      const newData = tableData.filter(
        d => d[tableProps.rowKeyField] !== rowKeyValue
      );

      const newDataWithNewKeys = newData.map((d, index) => {
        return {
          ...d,
          EasyLineId: index + 1,
        };
      });

      const newSelectedRows = mountSelectedRows({
        rowKeyValuesToDeleted: [rowKeyValue],
      });

      setComponentState(
        'tableProps',
        oldProps => ({
          ...oldProps,
          data: newDataWithNewKeys,
          selectedRows: newSelectedRows,
        }),
        true
      );
    }
  };

  const removeLine = async props => {
    const { rowKeyValue } = props;
    const onRemoveLineEvent = reportSettings.onRemoveLine;

    if (!onRemoveLineEvent) {
      return deleteRow(rowKeyValue);
    }

    const onRemoveLineReturn = await dynamicFunction({
      functionString: onRemoveLineEvent,
      settings,
      customModalRef,
      dispatch,
      promptRef,
      params: {
        ...props,
        ...auxScope,
        items: tableProps.data,
        rawItems: rawData,
      },
    });

    const shouldRemove =
      onRemoveLineReturn === undefined ? true : !!onRemoveLineReturn;

    if (shouldRemove) {
      deleteRow(rowKeyValue);
    }
  };

  const removeLines = async listRowKeyValues => {
    const onRemoveLineEvent = reportSettings.onRemoveLine;

    if (!onRemoveLineEvent) {
      return deleteRows(listRowKeyValues);
    }

    const onRemoveLineReturn = await dynamicFunction({
      functionString: onRemoveLineEvent,
      settings,
      customModalRef,
      dispatch,
      promptRef,
      params: {
        listRowKeyValues,
        ...auxScope,
        items: tableProps.data,
        rawItems: rawData,
      },
    });

    const shouldRemove =
      onRemoveLineReturn === undefined ? true : !!onRemoveLineReturn;

    if (shouldRemove) {
      deleteRows(listRowKeyValues);
    }
  };

  /** @type {GetSwipeButtonsSettings} */
  function getSwipeButtonsSettings({ rowProps }) {
    const { rowData, isSelectedRow, rowKeyValue, rowKeyField } = rowProps;

    const buttons = columns.filter(c => {
      if (c.settings && c.settings.type === 'button') {
        return true;
      }

      return false;
    });

    const leftButtons = [];
    const rightButtons = [];

    buttons.forEach(button => {
      const [columnButton] = columns.filter(
        btn => btn.key === button.settings.name
      );

      button.settings.title = columnButton.title;

      const onClickFunction = async () => {
        const column = columns.find(col => col.key === button.settings.name);

        await dynamicFunction({
          dispatch: appDispatch,
          functionString: button.settings.onClick,
          settings,
          promptRef,
          params: {
            column,
            line: rowData,
            value: rowData[button.settings.name],
            refresh: onRefresh,
            showLoading,
            paramsModal,
            reportSettings,
            globalFunctions: reportSettings.globalFunctions,
            setFieldValue: (columnKey, newValue, forceUpdate) => {
              setRowFieldValue(rowKeyValue, columnKey, newValue, forceUpdate);
            },
            setRowFieldReadOnly: (
              rkValue,
              columnKey,
              newValue,
              forceUpdate
            ) => {
              setRowFieldReadOnly(rkValue, columnKey, newValue, forceUpdate);
            },
            selectRow: ({ force = false }) => {
              dispatch(
                validateAndSelectRow({
                  callingFromCell: {
                    column: button,
                    dispatch,
                    editingMode: tableProps.editingMode,
                    field: button.key,
                    isSelectedRow,
                    rowData,
                    rowKeyField,
                    rowKeyValue,
                    selectedRows: tableProps.selectedRows,
                    value: rowData[button.key],
                  },
                  forceSelection: force,
                })
              );
            },
            deselectRow: () => {
              dispatch(deselectRow(rowProps.rowKeyValue));
            },
            items: tableProps.data,
            rawItems: rawData,
            ...auxScope,
            removeLine,
            removeLines,
          },
        });
      };

      const swipeButton = {
        content: (
          <SwipeButton
            settings={button.settings}
            onClick={async () => {
              await onClickFunction();
              if (
                modalDetailButtonsRef.current &&
                modalDetailButtonsRef.current.close
              ) {
                modalDetailButtonsRef.current.close();
              }

              if (
                modalSwipeButtonsRef.current &&
                modalSwipeButtonsRef.current.close
              ) {
                modalSwipeButtonsRef.current.close();
              }

              setComponentState('detailModalIsOpen', false, true);
            }}
          />
        ),
        action: async () => {
          await onClickFunction();
        },
      };

      const status = rowData[button.settings.name];

      if (status !== 'N' && status !== 'H' && status !== null) {
        const hideSwipe = button.settings.hideSwipe || false;

        swipeButton.hideSwipe = hideSwipe;

        if (!button.settings.swipe) {
          button.settings.swipe = {
            direction: 'right',
            icon: 'HiOutlineDocument',
          };
        }
        if (
          button.settings.swipe.direction === 'right' ||
          button.settings.swipe.direction == null
        ) {
          rightButtons.push(swipeButton);
        } else {
          leftButtons.push(swipeButton);
        }
      }
    });

    const swipeLeftMore = {
      content: (
        <SwipeButton
          settings={{
            title: t('Menu'),
            swipe: {
              direction: 'left',
              icon: 'FaEllipsisV',
              iconColor: '#fff',
              backgroundColor: '#449dc1',
              color: '#fff',
            },
          }}
        />
      ),
      action: () => {
        rowDataRef.current = rowData;
        modalSwipeButtonsRef.current.open({ buttons: leftButtons });
      },
    };

    const swipeRightMore = {
      content: (
        <SwipeButton
          settings={{
            title: t('Menu'),
            swipe: {
              direction: 'right',
              icon: 'FaEllipsisV',
              iconColor: '#fff',
              backgroundColor: '#449dc1',
              color: '#fff',
            },
          }}
        />
      ),
      action: () => {
        rowDataRef.current = rowData;
        modalSwipeButtonsRef.current.open({ buttons: rightButtons });
      },
    };

    let swipeLeft = null;

    const leftButtonsToShow = leftButtons.filter(b => b.hideSwipe === false);

    switch (leftButtonsToShow.length) {
      case 0:
        swipeLeft = null;
        break;

      case 1:
        swipeLeft = leftButtonsToShow[0];
        break;

      default:
        swipeLeft = swipeLeftMore;
        break;
    }

    let swipeRight = null;

    const rightButtonsToShow = rightButtons.filter(b => b.hideSwipe === false);

    switch (rightButtonsToShow.length) {
      case 0:
        swipeRight = null;
        break;

      case 1:
        swipeRight = rightButtonsToShow[0];
        break;

      default:
        swipeRight = swipeRightMore;
        break;
    }

    return {
      swipeLeft,
      swipeRight,
      leftButtons,
      rightButtons,
    };
  }

  const footerButtons = reportSettings.footerButtons || [];

  const optionsGroup = useMemo(() => {
    if (
      reportSettings.optionsGroup &&
      reportSettings.optionsGroup.length > 0 &&
      columns
    ) {
      const options = reportSettings.optionsGroup.map(group => {
        const column = columns.find(col => col.key === group);
        return {
          value: group,
          label: column ? column.title : group,
        };
      });

      return options;
    }

    return null;
  }, [columns, reportSettings.optionsGroup]);

  const dispatchOnUploadInLine = useCallback(
    ({ file, rowKeyValue, column }) =>
      onUploadInLine({ file, rowKeyValue, column }),
    [onUploadInLine]
  );

  const dispatchOnSuccessUploadInLine = useCallback(
    ({ file, rowKeyValue, column }) =>
      onSuccessUploadInLine({ file, rowKeyValue, column }),
    [onSuccessUploadInLine]
  );

  const dispatchOnValidateUploadInLine = useCallback(
    ({ file, rowKeyValue, column, tableSettings }) =>
      onValidateUploadInLine({ file, rowKeyValue, column, tableSettings }),
    [onValidateUploadInLine]
  );

  const dispatchOnDeleteUploadInLine = useCallback(
    ({ value, rowKeyValue, column }) =>
      onDeleteUploadInLine({ value, rowKeyValue, column }),
    [onDeleteUploadInLine]
  );

  const dispatchOnSuccessDeleteUploadInLine = useCallback(
    ({ value, rowKeyValue, column }) =>
      onSuccessDeleteUploadInLine({ value, rowKeyValue, column }),
    [onSuccessDeleteUploadInLine]
  );

  const dispatchOnOpenInputLinkerInLine = useCallback(
    params => {
      if (onOpenInputLinkerInLine) {
        return onOpenInputLinkerInLine(params);
      }
      return [];
    },
    [onOpenInputLinkerInLine]
  );

  const excelColumns = useMemo(() => {
    const { excelColumns: settingsExcelColumns = [] } = reportSettings;

    if (settingsExcelColumns.length > 0) {
      return tableProps.columns.filter(column =>
        settingsExcelColumns.includes(column.key)
      );
    }

    return tableProps.columns;
  }, [reportSettings, tableProps.columns]);

  /* const removeLine = rowKeyValue => {
    dispatch(deleteRow(rowKeyValue));
  }; */

  const removeLineOption = {
    text: 'Remover Linha',
    icon: 'FaTrash',
    onClick: removeLine,
  };

  const contextMenuOptions = [];

  if (reportSettings.newLine) {
    contextMenuOptions.push(removeLineOption);
  }

  const transformHandlerInOnClick = ({
    menus,
    extendedEvent,
    rowData,
    rowProps,
  }) => {
    return menus.map(menu => {
      if (!menu.onClick && !!menu.handler) {
        menu.onClick = async function onClick({ event }) {
          event.stopPropagation();
          const response = await dynamicFunction({
            functionString: menu.handler || '',
            settings,
            customModalRef,
            dispatch,
            promptRef,
            params: {
              ...auxScope,
              ...extendedEvent,
              rowData,
              items: tableProps.data,
              rawItems: rawData,
              ...rowProps,
              contextMenus: contextMenuOptions,
            },
          });

          return response;
        };
      }

      return menu;
    });
  };

  const dispatchOnContextMenuEvent = ({ extendedEvent, rowData, rowProps }) => {
    return dynamicFunction({
      functionString: reportSettings.onShowContextMenus || 'return null;',
      settings,
      customModalRef,
      dispatch,
      promptRef,
      params: {
        ...auxScope,
        ...(extendedEvent || {}),
        rowData,
        ...(rowProps || {}),
        contextMenus: contextMenuOptions,
        items: tableProps.data,
        rawItems: rawData,
        addLine: clickOnAddLine,
      },
    });
  };

  const isResponsible =
    reportSettings.isResponsible !== null &&
    reportSettings.isResponsible !== undefined
      ? reportSettings.isResponsible
      : true;

  let importColumns = tableProps.columns.map(col => ({
    key: col.key,
    label: col.title,
  }));

  if (
    reportSettings.columnsImportTemplate &&
    Array.isArray(reportSettings.columnsImportTemplate) &&
    reportSettings.columnsImportTemplate.length
  ) {
    importColumns = reportSettings.columnsImportTemplate.reduce(
      (importColumnsBuild, columnKey) => {
        const column = tableProps.columns.find(c => c.key === columnKey);

        if (!column) return importColumnsBuild;

        return [
          ...importColumnsBuild,
          {
            key: columnKey,
            label: column.title,
          },
        ];
      },
      []
    );
  }

  const getClientEvent = (eventName, run) => {
    if (!reportSettings.events) return;

    if (!Array.isArray(reportSettings.events)) return;

    return reportSettings.events.find(e => {
      if (e.name !== eventName) return false;

      if (!run) return e.run === 'before' || !e.run;

      return e.run === run;
    });
  };

  const onImportLines = async newLines => {
    const event = getClientEvent('onImportLines');

    if (!event) return newLines;

    const response = await dynamicFunction({
      dispatch,
      functionString: event.handler,
      settings,
      promptRef,
      customModalRef,
      params: {
        ...auxScope,
        columns,
        data: tableProps.data,
        rawData,
        importingLines: newLines,
        refresh: onRefresh,
        showLoading,
        paramsModal,
        reportSettings,
        getSelectedData,
        removeLine,
        removeLines,
        setRowFieldValue,
        refreshTable,
        setRowFieldReadOnly,
      },
    });

    if (!response) return newLines;

    return response;
  };

  const onImportIndividualLine = async newLines => {
    const event = getClientEvent('onImportLine');

    if (!event) return newLines;

    const responsePromises = newLines.map(line => {
      return dynamicFunction({
        dispatch,
        functionString: event.handler,
        settings,
        promptRef,
        customModalRef,
        params: {
          ...auxScope,
          columns,
          data: line,
          items: tableProps.data,
          rawItems: rawData,
          importingLines: newLines,
          refresh: onRefresh,
          showLoading,
          paramsModal,
          reportSettings,
          getSelectedData,
          removeLine,
          removeLines,
          setRowFieldValue,
          refreshTable,
          setRowFieldReadOnly,
        },
      });
    });

    const response = await Promise.all(responsePromises);

    if (!response.length) return newLines;

    return response;
  };

  let hasExportation = true;

  if (
    reportSettings.hasExportation !== null &&
    reportSettings.hasExportation !== undefined
  ) {
    hasExportation = reportSettings.hasExportation;
  }

  const getCellContentProps = ({ props: cellProps }) => {
    let style = null;

    if (reportSettings.width) {
      style = {
        minWidth: `${reportSettings.width}px`,
        maxWidth: `${reportSettings.width}px`,
        width: `${reportSettings.width}px`,
      };
    }

    if (cellProps.column && cellProps.column.settings) {
      if (cellProps.column.settings.cellStyle) {
        const cellStyleFunction = getCellStyleFunction(
          cellProps.column.settings.cellStyle
        );

        if (cellStyleFunction) {
          const styleScope = {
            data: cellProps.rowData,
            value: cellProps.value,
            rows: tableProps.data,
          };

          style = cellStyleFunction(styleScope);
        }
      } else if (cellProps.column.settings.width) {
        style = {
          minWidth: `${cellProps.column.settings.width}px`,
          maxWidth: `${cellProps.column.settings.width}px`,
          width: `${cellProps.column.settings.width}px`,
        };
      }
    }

    const isCellReadOnly =
      tableProps.readOnlyInputs.some(
        cell =>
          cellProps.column &&
          cellProps.column.key === cell.columnKey &&
          cellProps.rowKeyValue === cell.rowKeyValue
      ) ||
      (tableProps.readOnlyColumns &&
        tableProps.readOnlyColumns.includes(
          cellProps.column && cellProps.column.key
        ));

    const isLineReadOnly = cellProps.rowData && !!cellProps.rowData._readOnly;

    const isFocused =
      tableProps.focused &&
      tableProps.focused.cell &&
      tableProps.focused.cell.rowKeyValue === cellProps.rowKeyValue &&
      tableProps.focused.cell.columnKey ===
        (cellProps.column && cellProps.column.key);

    const isSelectedRow =
      !!tableProps.selectedRows &&
      tableProps.selectedRows.some(
        row => row.toString() === cellProps.rowKeyValue?.toString()
      );

    const isOpenEditor =
      tableProps.editableCells &&
      tableProps.editableCells.some(
        cell =>
          cell.rowKeyValue === cellProps.rowKeyValue &&
          cell.columnKey === cellProps.column.key
      );

    return {
      field: cellProps.field,
      column: cellProps.column,
      rowData: cellProps.rowData,
      value: cellProps.value,
      rowKeyValue: cellProps.rowKeyValue,
      dispatch: cellProps.dispatch,
      props: cellProps,
      isOpenEditor,
      settings,
      onRefresh,
      showLoading,
      paramsModal,
      style: style || (cellProps.column && cellProps.column.style),
      items: tableProps.data,
      rawItems: rawData,
      reportSettings,
      readOnly: isCellReadOnly || isLineReadOnly,
      isFocused,
      getSelectedData,
      onAddNewLine: currentData => {
        return addNewLine(currentData);
      },
      isSelectedRow,
      auxScope,
    };
  };

  const handleFocus = ({ rowKeyValue, column }) => {
    const hasInput = columns.some(c => {
      if (!c) return false;

      if (!c.visible) return false;

      if (!c.settings) return false;

      if (typeof c.settings.type !== 'string') {
        return false;
      }

      return c.settings.type.startsWith('input');
    });

    if (!hasInput) return;

    const editableCells = tableProps.editableCells || [];

    const openedRow =
      editableCells.length > 0 ? tableProps.editableCells[0].rowKeyValue : null;

    if (+openedRow === +rowKeyValue) {
      return;
    }

    dispatch(
      setFocused({
        cell: {
          rowKeyValue,
          columnKey: column.key,
        },
      }),
      false
    );
    dispatch(openRowEditors(rowKeyValue));
  };

  /**
   * @param {import('ka-table/props').ICellProps} props
   * @returns {void}
   */
  const callCustomOnClick = async cellProps => {
    const { column, rowData, rowKeyValue, value } = cellProps;

    if (!reportSettings.onCellClick) {
      return;
    }

    await dynamicFunction({
      functionString: reportSettings.onCellClick,
      settings,
      dispatch: appDispatch,
      promptRef,
      customModalRef,
      params: {
        column,
        line: rowData,
        items: tableProps.data,
        rawItems: tableProps.data,
        setFieldValue: (columnKey, newValue, forceUpdate) => {
          setRowFieldValue(rowKeyValue, columnKey, newValue, forceUpdate);
        },
        value,
        refresh: onRefresh,
        showLoading,
        userInfo,
        deleteRow: () => dispatch(deleteRow(rowKeyValue)),
        paramsModal,
        reportSettings,
        selectRow: ({ force = false }) => {
          dispatch(
            validateAndSelectRow({
              callingFromCell: cellProps,
              forceSelection: force,
            })
          );
        },
        deselectRow: () => {
          dispatch(deselectRow(rowKeyValue));
        },
        ...auxScope,
        removeLine,
      },
    });
  };

  useEffect(() => {
    reloadStateThatReliesOnProps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, reportSettings, isReadOnly]);

  useEffect(() => {
    onChange(tableProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableProps]);

  useEffect(() => {
    if (!reportSettings.newLine) return;

    if (!tableProps.columns.find(column => column.key === 'EasyLineId')) {
      addEasyLineIdColumn();
    }
  }, [addEasyLineIdColumn, reportSettings.newLine, tableProps.columns]);

  const automaticSelectedAllRowsRef = useRef(false);

  useEffect(() => {
    if (!reportSettings.initialRowsSelected) return;

    if (!tableProps.data) return;

    if (!tableProps.data.length) return;

    if (automaticSelectedAllRowsRef.current) return;

    automaticSelectedAllRowsRef.current = true;

    dispatch(selectAllRows());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportSettings.initialRowsSelected, tableProps.data]);

  const onExportLines = async exportingLines => {
    const event = getClientEvent('onExportLines');

    if (!event) return exportingLines;

    const response = await dynamicFunction({
      dispatch,
      functionString: event.handler,
      settings,
      promptRef,
      customModalRef,
      params: {
        ...auxScope,
        columns,
        data: tableProps.data,
        rawData,
        exportingLines,
        refresh: onRefresh,
        showLoading,
        paramsModal,
        reportSettings,
        getSelectedData,
        removeLine,
        removeLines,
        setRowFieldValue,
        refreshTable,
        setRowFieldReadOnly,
      },
    });

    if (!response) return exportingLines;

    return response;
  };

  if (!tableProps.columns.length && !tableProps.data.length) {
    return onEmpty();
  }

  return (
    <PageContext.Provider
      value={{
        getSwipeButtonsSettings,
        modalDetailButtonsRef,
        promptRef,
        customModalRef,
        dispatch,
        paramsModal,
        getSelectedData,
        showLoading,
        auxScope,
        dispatchOnUploadInLine,
        dispatchOnSuccessUploadInLine,
        dispatchOnValidateUploadInLine,
        dispatchOnDeleteUploadInLine,
        dispatchOnSuccessDeleteUploadInLine,
        dispatchOnOpenInputLinkerInLine,
        onGetDownloadToken,
        setRowFieldValue,
        refreshTable,
        setRowFieldReadOnly,
        removeLine,
        removeLines,
        settings,
        dispatchOnContextMenuEvent,
        contextMenuOptions,
        transformHandlerInOnClick,
        isResponsible,
        getCellStyleFunction,
        items: tableProps.data,
        reportSettings,
        tableProps,
        rowKeyField: tableProps.rowKeyField,
        getCellContentProps,
        t,
        appDispatch,
      }}
    >
      <>
        <Container ref={containerRef}>
          <Header t={t} reportSettings={reportSettings} RenderProp={header} />
          {!(hideToolbar || reportSettings.hideToolbar) && (
            <Toolbar>
              <Form
                ref={formRef}
                style={{ justifyContent: 'center', alignItems: 'flex-start' }}
              >
                {optionsGroup && (
                  <GroupBy
                    title={groupTitle}
                    options={optionsGroup}
                    onClear={() => {
                      setComponentState(
                        'tableProps',
                        oldState => ({
                          ...oldState,
                          groups: null,
                        }),
                        true
                      );
                    }}
                    onSelect={selected => {
                      setComponentState(
                        'tableProps',
                        {
                          ...tableProps,
                          groups: [
                            {
                              columnKey: selected.value,
                            },
                          ],
                        },
                        true
                      );
                    }}
                  />
                )}
              </Form>
              {hasExportation && (
                <>
                  <PDFButton
                    type="button"
                    onClick={() => exportClick(pdfOrientation)}
                    data-tip={t('PDFDownload')}
                    data-for="json-table-tooltip"
                  >
                    <Icon size={24} color="#353c44" name="GrDocumentPdf" />
                  </PDFButton>
                  <CSVLinkWrapper>
                    <CSVExportButton
                      excelColumns={excelColumns}
                      fileName={`${fileName}.csv`}
                      onExportLines={() =>
                        onExportLines(getVisibleData({ linkerMode: 'value' }))
                      }
                      t={t}
                    />
                  </CSVLinkWrapper>
                </>
              )}
              {reportSettings.hasImportation && (
                <ImportationTool
                  importColumns={importColumns}
                  onExportStart={() => showLoading(true)}
                  onExportSuccess={() => {
                    showLoading(false);
                    Toast.info(t('message.ExportCsvToClipboardSuccess'));
                  }}
                  hasDivision={hasExportation}
                  t={t}
                  onImportSuccess={async newData => {
                    try {
                      showLoading(true);
                      const finalData = [...tableProps.data];

                      let treatedNewData = await onImportLines(newData);

                      treatedNewData = await onImportIndividualLine(
                        treatedNewData
                      );

                      if (reportSettings.newLine === 'before') {
                        finalData.unshift(...treatedNewData);
                      } else {
                        finalData.push(...treatedNewData);
                      }

                      setComponentState(
                        'tableProps',
                        prevState => ({
                          ...prevState,
                          data: finalData.map((item, index) => ({
                            ...item,
                            EasyLineId: index + 1,
                          })),
                        }),
                        true
                      );

                      const onImportLinesAfterEvent = getClientEvent(
                        `onImportLines`,
                        `after`
                      ) || { handler: '' };

                      if (
                        onImportLinesAfterEvent &&
                        onImportLinesAfterEvent.handler
                      ) {
                        await dynamicFunction({
                          dispatch,
                          functionString: onImportLinesAfterEvent.handler,
                          settings,
                          promptRef,
                          customModalRef,
                          params: {
                            ...auxScope,
                            columns,
                            data: tableProps.data,
                            rawData,
                            refresh: onRefresh,
                            showLoading,
                            paramsModal,
                            reportSettings,
                            getSelectedData,
                            removeLine,
                            removeLines,
                            setRowFieldValue,
                            setRowFieldReadOnly,
                          },
                        });
                      }

                      Toast.success(t('message.SystemMessageSuccess'));
                    } catch (err) {
                      TreatError.showError(err);
                    } finally {
                      showLoading(false);
                    }
                  }}
                  onImportError={() => Toast.info('error.EmptyCsv')}
                />
              )}
              {reportSettings.newLine && !isReadOnly && (
                <AddLineButton
                  type="button"
                  onClick={clickOnAddLine}
                  data-tip={t('AddLine')}
                  data-for="json-table-tooltip"
                >
                  <Icon size={24} color="#353c44" name="RiMenuAddFill" />
                </AddLineButton>
              )}
            </Toolbar>
          )}
          {(hideToolbar || reportSettings.hideToolbar) &&
            !!reportSettings.newLine &&
            !isReadOnly && (
              <Toolbar>
                <AddLineButton
                  type="button"
                  onClick={clickOnAddLine}
                  data-tip={t('AddLine')}
                  data-for="json-table-tooltip"
                >
                  <Icon size={24} color="#353c44" name="RiMenuAddFill" />
                </AddLineButton>
              </Toolbar>
            )}
          <Content id="JsonTableContent" cellDensity={tableProps.cellDensity}>
            <ResponsiveContainer isResponsible={isResponsible}>
              {/* <Form ref={inputsFormRef}> */}
              <Table
                {...tableProps}
                dispatch={dispatch}
                childComponents={{
                  tableWrapper: {
                    elementAttributes: () => {
                      return {
                        onScroll: (event, { baseFunc }) => {
                          baseFunc(event);

                          if (
                            reportSettings.infinityScroll &&
                            !reportSettings.newLine
                          ) {
                            const element = event.currentTarget;
                            const BOTTOM_OFFSET = 35;

                            if (
                              element.offsetHeight + element.scrollTop >=
                              element.scrollHeight - BOTTOM_OFFSET
                            ) {
                              dispatch({ type: 'loadMoreData' });
                            }
                          }
                        },
                      };
                    },
                  },
                  headCell: {
                    content: props => {
                      if (props.column.settings) {
                        if (
                          props.column.settings.type === 'selection' &&
                          !reportSettings.disableSelectAll
                        ) {
                          return (
                            <SelectionHeader
                              {...props}
                              areAllRowsSelected={kaPropsUtils.areAllFilteredRowsSelected(
                                tableProps
                              )}
                            />
                          );
                        }
                      }
                    },
                    elementAttributes: props => {
                      const { column } = props;

                      const style = {
                        textAlign: 'left',
                        padding: tableProps.cellDensityPadding,
                      };

                      if (column.settings) {
                        const { type } = column.settings;

                        if (type === 'number') style.textAlign = 'right';

                        if (type === 'date') style.textAlign = 'center';
                      }

                      if (
                        column.settings &&
                        column.settings.fixedColumn === true
                      ) {
                        style.position = 'sticky';
                        style.left = 0;
                        style.zIndex = 100;
                        style.backgroundColor = '#eee';
                      }

                      return {
                        style,
                      };
                    },
                  },
                  dataRow: {
                    elementAttributes: ({ rowData, ...rowProps }) => {
                      let style = null;
                      if (getRowColor) {
                        style = {
                          backgroundColor: getRowColor({ data: rowData }),
                        };
                      }

                      return {
                        style,
                        onClick: (event, extendedEvent) => {
                          if (!isMultSelection) {
                            extendedEvent.dispatch(
                              selectSingleRow(
                                extendedEvent.childProps.rowKeyValue
                              )
                            );

                            if (onSelect) onSelect({ data: rowData });
                          }
                        },
                        onContextMenu: (event, extendedEvent) => {
                          const target = event.target;
                          event.preventDefault();

                          contextMenuRef.current.open({
                            ...rowProps,
                            ...extendedEvent,
                            rowData,
                            clientX: event.clientX,
                            clientY: event.clientY,
                            rowElement: target.closest('tr'),
                            loading: true,
                          });

                          dispatchOnContextMenuEvent({
                            extendedEvent,
                            rowProps,
                            rowData,
                          })
                            .then(menus => {
                              if (menus && Array.isArray(menus)) {
                                contextMenuRef.current.changeMenus(
                                  transformHandlerInOnClick({
                                    menus,
                                    rowData,
                                    rowProps,
                                    extendedEvent,
                                  })
                                );
                              } else {
                                contextMenuRef.current.changeMenus(
                                  contextMenuOptions
                                );
                              }
                            })
                            .finally(() => {
                              contextMenuRef.current.setLoading(false);
                            });

                          return false;
                        },
                      };
                    },
                    content: props => {
                      if (
                        isMobile &&
                        reportSettings &&
                        (reportSettings.mobileCardTemplate ||
                          reportSettings.mobileCardTemplateJSX)
                      ) {
                        const buttons = getSwipeButtonsSettings({
                          rowProps: props,
                        });

                        let style = null;
                        let containerStyle = null;

                        if (props.column && props.column.settings) {
                          const cellStyleFunction = getCellStyleFunction(
                            props.column.settings.cellStyle
                          );
                          if (cellStyleFunction) {
                            style = cellStyleFunction({
                              data: props.rowData,
                              value: props.value,
                              rows: tableProps.data,
                            });
                          }
                        }

                        if (props.columns) {
                          props.columns.forEach(column => {
                            if (
                              column.settings &&
                              column.settings.type === 'template'
                            ) {
                              const cellStyleFunction = getCellStyleFunction(
                                column.settings.containerStyle
                              );
                              if (cellStyleFunction) {
                                containerStyle = cellStyleFunction({
                                  data: props.rowData,
                                  value: props.value,
                                  rows: tableProps.data,
                                });
                              }
                            }
                          });
                        }

                        return (
                          <SwipeableList scrollStartThreshold>
                            <SwipeableListItem
                              swipeLeft={buttons.swipeRight}
                              swipeRight={buttons.swipeLeft}
                            >
                              {reportSettings.mobileCardTemplateJSX ? (
                                <MobileCardJSX
                                  jsxString={
                                    reportSettings.mobileCardTemplateJSX
                                  }
                                  dynamicFunctionProp={{
                                    dispatch: appDispatch,
                                    settings,
                                    promptRef,
                                    customModalRef,
                                    params: {
                                      ...auxScope,
                                      ...props,
                                      columns,
                                      data: tableProps.data,
                                      rawData,
                                      refresh: onRefresh,
                                      showLoading,
                                      paramsModal,
                                      reportSettings,
                                      getSelectedData,
                                      removeLine,
                                      removeLines,
                                      setRowFieldValue,
                                      refreshTable,
                                      setRowFieldReadOnly,
                                    },
                                  }}
                                  onClick={() => {
                                    setComponentState(
                                      'mobileSelectedRowKeyValue',
                                      props.rowKeyValue
                                    );
                                    dispatch(
                                      openRowEditors(props.rowKeyValue),
                                      false
                                    );
                                    setComponentState(
                                      'detailModalIsOpen',
                                      true
                                    );
                                    updateComponent();
                                  }}
                                />
                              ) : (
                                <Card
                                  templateFields={cardTemplateFields}
                                  columns={props.columns}
                                  data={props.rowData}
                                  reportSettings={reportSettings}
                                  settings={settings}
                                  onRefresh={onRefresh}
                                  getRowColor={getRowColor}
                                  props={props}
                                  style={style}
                                  containerStyle={containerStyle}
                                  items={tableProps.data}
                                  onClick={() => {
                                    setComponentState(
                                      'mobileSelectedRowKeyValue',
                                      props.rowKeyValue
                                    );
                                    dispatch(
                                      openRowEditors(props.rowKeyValue),
                                      false
                                    );
                                    setComponentState(
                                      'detailModalIsOpen',
                                      true
                                    );
                                    updateComponent();
                                  }}
                                />
                              )}
                            </SwipeableListItem>
                          </SwipeableList>
                        );
                      }
                      return null;
                    },
                  },
                  cell: {
                    elementAttributes: cellProps => {
                      const { column } = cellProps;
                      let style = {
                        padding: tableProps.cellDensityPadding,
                      };

                      if (
                        column.settings &&
                        column.settings.type === 'template'
                      ) {
                        style = {
                          background: 'transparent',
                          padding: 0,
                        };
                      }

                      if (
                        column.settings &&
                        column.settings.fixedColumn === true
                      ) {
                        style = {
                          position: 'sticky',
                          left: 0,
                          backgroundColor: '#eee',
                          padding: tableProps.cellDensityPadding,
                        };
                      }

                      return {
                        'data-column': column.title,
                        style,
                        onClick: async () => {
                          handleFocus(cellProps);
                          await callCustomOnClick(cellProps);
                        },
                      };
                    },
                  },
                  cellText: {
                    content: props => {
                      const cellContentProps = getCellContentProps({
                        props,
                      });

                      return <CellContent {...cellContentProps} />;
                    },
                    elementAttributes: ({ column }) => {
                      let style = null;

                      if (column && column.settings) {
                        if (column.settings.style) {
                          style = column.settings.style;
                        }
                      }

                      return { style };
                    },
                  },
                  filterRowCell: {
                    content: props => {
                      // eslint-disable-next-line react/destructuring-assignment
                      const { settings: columnSettings } = props.column;

                      if (columnSettings) {
                        const notExports = ['button', 'selection'];
                        if (notExports.includes(columnSettings.type)) {
                          return <></>;
                        }

                        if (!columnSettings.dataType) {
                          return (
                            <TextFilter
                              {...props}
                              value={props.column.filterRowValue}
                            />
                          );
                        }

                        switch (columnSettings.dataType) {
                          case 'date':
                            return (
                              <DateFilter
                                {...props}
                                value={props.column.filterRowValue}
                              />
                            );

                          case 'number':
                            return (
                              <NumberFilter
                                {...props}
                                value={props.column.filterRowValue}
                              />
                            );

                          case 'boolean':
                            return <TextFilter {...props} disabled />;

                          default:
                            return (
                              <TextFilter
                                {...props}
                                value={props.column.filterRowValue}
                              />
                            );
                        }
                      }
                    },
                    elementAttributes: ({ column }) => {
                      let style = null;

                      if (
                        column.settings &&
                        column.settings.fixedColumn === true
                      ) {
                        style = {
                          position: 'sticky',
                          left: 0,
                          zIndex: 100,
                          backgroundColor: '#eee',
                        };
                      }

                      return {
                        style,
                      };
                    },
                  },
                  groupCell: {
                    content: props => {
                      return <CellGroup cellProps={props} />;
                    },
                    elementAttributes: ({ column }) => {
                      let style = null;

                      if (column && column.settings) {
                        if (column.settings.style) {
                          style = column.settings.style;
                        }
                      }

                      return { style };
                    },
                  },
                  groupSummaryRow: {
                    elementAttributes: ({ columns: tableColumns }) => {
                      if (
                        !tableColumns.find(
                          column => !!column.settings.onSummary
                        )
                      ) {
                        return {
                          style: {
                            display: 'none',
                          },
                        };
                      }
                    },
                  },
                  groupSummaryCell: {
                    content: groupScope => {
                      const { column = {} } = groupScope;

                      const { settings: columnSettings = {} } = column;

                      const { onSummary } = columnSettings;

                      return (
                        <CellSummary
                          functionString={onSummary}
                          scope={{
                            data: tableProps.data,
                            rawData,
                            ...groupScope,
                            ...auxScope,
                            reportSettings,
                            getSelectedData,
                          }}
                          settings={settings}
                        />
                      );
                    },
                  },
                  summaryRow: {
                    elementAttributes: ({ columns: tableColumns }) => {
                      if (
                        !tableColumns.find(column =>
                          column.settings ? !!column.settings.onSummary : false
                        )
                      ) {
                        return {
                          style: {
                            display: 'none',
                          },
                        };
                      }

                      return {
                        style: {
                          position: 'sticky',
                          bottom: 0,
                          left: 0,
                          backgroundColor: 'white',
                          borderTop: '1px solid #555',
                        },
                      };
                    },
                  },
                  summaryCell: {
                    content: props => {
                      const { column } = props;

                      const { settings: columnSettings = {} } = column;

                      let onSummary = null;

                      if (columnSettings) {
                        const { onSummary: summary } = columnSettings;

                        onSummary = summary;
                      }

                      const columnData = props.data.map(d => {
                        return d[column.key];
                      });

                      return (
                        <CellSummary
                          columnKey={column.key}
                          columnData={columnData}
                          functionString={onSummary || null}
                          scope={{
                            ...props,
                            ...auxScope,
                            reportSettings,
                            getSelectedData,
                            groupData: props.data,
                            data: tableProps.data,
                            rawData,
                          }}
                          settings={settings}
                        />
                      );
                    },
                  },
                  cellEditor: {
                    content: props => {
                      const cellContentProps = getCellContentProps({
                        props,
                      });

                      return <CellContent {...cellContentProps} />;
                    },
                    elementAttributes: ({ column }) => {
                      let style = null;

                      if (column && column.settings) {
                        if (column.settings.style) {
                          style = column.settings.style;
                        }
                      }

                      return { style };
                    },
                  },
                  noDataRow: {
                    content: () => {
                      if (!tableProps.data.length) {
                        return (
                          <DefaultEmptyContainer>
                            {reportSettings.emptyMessage ||
                              t('message.NoRegisterFound')}
                          </DefaultEmptyContainer>
                        );
                      }
                    },
                  },
                }}
              />
              {/* </Form> */}
            </ResponsiveContainer>
          </Content>
          <Resume>
            {`${rawData.length} ${t('Record')}`}
            <FullScreenButton containerRef={containerRef} />
          </Resume>
          <SwipeModalButtons ref={modalSwipeButtonsRef} />
          {isMobile && (
            <ModalDetail
              // columns={tableProps.columns}
              // data={mobileSelectedRow}
              isOpen={detailModalIsOpen}
              // onRefresh={onRefresh}
              rowKeyValue={mobileSelectedRowKeyValue}
              onClose={() => {
                setComponentState('detailModalIsOpen', false, true);
              }}
            />
          )}
          {footerButtons.length > 0 && (
            <FooterBar>
              <div>
                {// eslint-disable-next-line array-callback-return
                footerButtons
                  .filter(
                    element =>
                      (element.position && element.position === 'left') ||
                      !element.position
                  )
                  .map(element => {
                    return (
                      <FooterButton
                        color={element.color}
                        iconName={element.iconName}
                        key={element.name}
                        isHidden={element.isHidden}
                        onCheckIsHidden={() => {
                          if (typeof element.isHidden === 'string') {
                            return dynamicFunction({
                              dispatch: appDispatch,
                              functionString: element.isHidden,
                              settings,
                              promptRef,
                              customModalRef,
                              params: {
                                ...auxScope,
                                columns,
                                data: tableProps.data,
                                rawData,
                                refresh: onRefresh,
                                showLoading,
                                paramsModal,
                                reportSettings,
                                getSelectedData,
                                removeLine,
                                removeLines,
                                setRowFieldValue,
                                refreshTable,
                                setRowFieldReadOnly,
                              },
                            });
                          }

                          return false;
                        }}
                        onClick={async () => {
                          await dynamicFunction({
                            dispatch: appDispatch,
                            functionString: element.handler,
                            settings,
                            promptRef,
                            customModalRef,
                            params: {
                              ...auxScope,
                              columns,
                              data: tableProps.data,
                              rawData,
                              refresh: onRefresh,
                              showLoading,
                              paramsModal,
                              reportSettings,
                              getSelectedData,
                              removeLine,
                              removeLines,
                              setRowFieldValue,
                              refreshTable,
                              setRowFieldReadOnly,
                            },
                          });
                        }}
                        position={element.position}
                        size={element.size}
                        t={t}
                        text={element.text}
                        toolTip={element.toolTip}
                        translateKey={element.translateKey}
                        translateRoute={element.translateRoute}
                        type={element.type}
                      />
                    );
                  })}
              </div>
              <FlexSpace />
              <div>
                {// eslint-disable-next-line array-callback-return
                footerButtons
                  .filter(
                    element => element.position && element.position === 'right'
                  )
                  .map(element => {
                    return (
                      <FooterButton
                        color={element.color}
                        iconName={element.iconName}
                        key={element.name}
                        isHidden={element.isHidden}
                        onCheckIsHidden={() => {
                          if (typeof element.isHidden === 'string') {
                            return dynamicFunction({
                              dispatch: appDispatch,
                              functionString: element.isHidden,
                              settings,
                              promptRef,
                              customModalRef,
                              params: {
                                ...auxScope,
                                columns,
                                data: tableProps.data,
                                rawData,
                                refresh: onRefresh,
                                showLoading,
                                paramsModal,
                                reportSettings,
                                getSelectedData,
                                removeLine,
                                removeLines,
                                setRowFieldValue,
                                refreshTable,
                                setRowFieldReadOnly,
                              },
                            });
                          }

                          return false;
                        }}
                        onClick={async () => {
                          await dynamicFunction({
                            dispatch: appDispatch,
                            functionString: element.handler,
                            settings,
                            promptRef,
                            customModalRef,
                            params: {
                              ...auxScope,
                              columns,
                              data: tableProps.data,
                              rawData,
                              refresh: onRefresh,
                              showLoading,
                              paramsModal,
                              reportSettings,
                              getSelectedData,
                              removeLine,
                              removeLines,
                              setRowFieldValue,
                              refreshTable,
                              setRowFieldReadOnly,
                            },
                          });
                        }}
                        position={element.position}
                        size={element.size}
                        t={t}
                        text={element.text}
                        toolTip={element.toolTip}
                        translateKey={element.translateKey}
                        translateRoute={element.translateRoute}
                        type={element.type}
                      />
                    );
                  })}
              </div>
              <ReactTooltip
                id="footerButtonsTooltip"
                place="top"
                type="light"
                effect="solid"
                className="toolbarTooltip"
              />
            </FooterBar>
          )}
        </Container>
        {isMobile &&
          tableProps.filteringMode &&
          tableProps.filteringMode !== 'none' && (
            <SearchMobileInput
              placeholder={`${t('Search')}...`}
              type="search"
              defaultValue={tableProps.searchText}
              onChange={event => {
                dispatch(search(event.currentTarget.value));
              }}
            />
          )}
        <PromptModal ref={promptRef} />
        <MenuPopUp ref={contextMenuRef} menus={contextMenuOptions} />
        <ReactTooltip
          id="json-table-tooltip"
          place="bottom"
          type="light"
          effect="solid"
          className="json-table-tooltip"
        />
      </>
    </PageContext.Provider>
  );
};

const JsonTableComponent = forwardRef(JsonTable);

JsonTableComponent.defaultProps = {
  reportSettings: {},
};

export default CompareRender(JsonTableComponent, [
  'data',
  'columns',
  'reportSettings',
  'groups',
  'isReadOnly',
  'onChange',
]);
