import React, { useContext } from 'react';
// eslint-disable-next-line import/no-cycle
import ImageViewer from '~/easy-components/ImageViewer';
import AuxInfo from '~/easy-components/AuxInfo';
import NoImage from '~/assets/no-image.png';
import useCompany from '~/hooks/useCompany';
import {
  Container,
  Content,
  ContentView,
  Description,
  InfoProducts,
  Title,
  Value,
  Checkbox,
  BoxSet,
  FirstLine,
  SecondLine,
  Essencial,
  Trace,
} from './styles';
import { ModalCatalogContext } from '../../context';
import QuantitySelector from '../QuantitySelector';

/**
 * @typedef {Object} ProductProps
 * @property {string} imageSrc
 * @property {string} title
 * @property {string} description
 * @property {string} value
 */

/**
 * @param {ProductProps} props
 */

function Product({ item }) {
  const { selectedItems, setSelectedItems, currency } = useContext(
    ModalCatalogContext
  );
  const { numberToString } = useCompany();

  return (
    <Container>
      <Content>
        <ContentView>
          <ImageViewer
            src={`${item.U_AHS_Image || NoImage}`}
            width="8rem"
            height="8rem"
          />
          <InfoProducts>
            <FirstLine>
              <Essencial>
                <Title>{item.ItemName}</Title>
                <Description>
                  {item.Category} / {item.ItemCode}
                </Description>
              </Essencial>
              <Trace>
                <AuxInfo text={item.AuxInfo || item.auxInfo} />
              </Trace>
            </FirstLine>
            <SecondLine>
              <Value>
                {` ${currency} ${numberToString(item.UnitPrice || 0, 'price')}`}
              </Value>
              <QuantitySelector item={item} />
            </SecondLine>
          </InfoProducts>
        </ContentView>
        <BoxSet>
          <Checkbox
            type="checkbox"
            checked={selectedItems.some(si => si.ItemCode === item.ItemCode)}
            onChange={e => {
              if (e.target.checked) {
                const updatedItems = [...selectedItems];
                updatedItems.push({ ...item, Quantity: 1 });

                setSelectedItems(updatedItems);
                return;
              }
              const newItems = selectedItems.filter(
                si => si.ItemCode !== item.ItemCode
              );
              setSelectedItems(newItems);
            }}
          />
        </BoxSet>
      </Content>
    </Container>
  );
}

export default Product;
