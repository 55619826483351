import React from 'react';
import loadable from '@loadable/component';
import AsyncRoute from '../../routes/AsyncRoute';

const BusinessPartners = loadable(() => import('./pages/BusinessPartners'));
const BusinessPartnerWizard = loadable(() =>
  import('./pages/BusinessPartnerWizard')
);
const BusinessPartnerWizardCRM = loadable(() =>
  import('./pages/BusinessPartnerWizardCRM')
);
const Vendors = loadable(() => import('./pages/Vendors'));
const SystemLogs = loadable(() => import('./pages/SystemLogs'));
const SalesOrders = loadable(() => import('./pages/SalesOrders'));
const ExpensesAdvance = loadable(() => import('./pages/ExpensesAdvance'));
const Expenses = loadable(() => import('./pages/Expenses'));
const FinancialPolicies = loadable(() => import('./pages/FinancialPolicies'));
const PurchaseOrders = loadable(() => import('./pages/PurchaseOrders'));
const PurchaseOrdersImport = loadable(() =>
  import('./pages/PurchaseOrdersImport')
);

const SalesQuotations = loadable(() => import('./pages/SalesQuotations'));
const DefaultBranch = loadable(() => import('./pages/DefaultBranch'));
const Items = loadable(() => import('./pages/Items'));
const SalesOpportunities = loadable(() => import('./pages/SalesOpportunities'));
const SalesOpportunitiesKanban = loadable(() =>
  import('./pages/SalesOpportunitiesKanban')
);
const Activities = loadable(() => import('./pages/Activities'));
const PurchaseRequests = loadable(() => import('./pages/PurchaseRequestNew'));
const Proposals = loadable(() => import('./pages/Proposals'));
const ReportQueries = loadable(() => import('./pages/ReportQueries'));
const BatchNumberDetails = loadable(() => import('./pages/BatchNumberDetails'));
const SerialNumberDetails = loadable(() =>
  import('./pages/SerialNumberDetails')
);
const SalesInvoices = loadable(() => import('./pages/SalesInvoices'));
const PurchaseInvoices = loadable(() => import('./pages/PurchaseInvoices'));
const ReturnRequests = loadable(() => import('./pages/ReturnRequests'));
const RequestForQuotationsWizard = loadable(() =>
  import('./pages/RequestForQuotationsWizard')
);
const PurchaseQuotations = loadable(() => import('./pages/PurchaseQuotations'));
const PurchaseQuotationsSupplier = loadable(() =>
  import('./pages/PurchaseQuotationsSupplier')
);
const PurchaseQuotationsSupplierModels = loadable(() =>
  import('./pages/PurchaseQuotationsSupplierModels')
);

const PurchaseQuotationClient = loadable(() =>
  import('./pages/PurchaseQuotationClient')
);
const BusinessPartnersAccess = loadable(() =>
  import('./pages/BusinessPartnersAccess')
);
const SalesRoutes = loadable(() => import('./pages/SalesRoutes'));
const BusinessPartnersPre = loadable(() =>
  import('./pages/BusinessPartnersPre')
);
const BusinessPartnersCRM = loadable(() =>
  import('./pages/BusinessPartnersCRM')
);
const Trucks = loadable(() => import('./pages/Trucks'));
const WarehouseRoutes = loadable(() => import('./pages/WarehouseRoutes'));
const TruckTypes = loadable(() => import('./pages/TruckTypes'));
const DefectiveItem = loadable(() => import('./pages/DefectiveItem'));
const DistributionReconciliation = loadable(() =>
  import('./pages/DistributionReconciliation')
);
const InventoryTransferRequests = loadable(() =>
  import('./pages/InventoryTransferRequests')
);
const GoogleMaps = loadable(() => import('./pages/GoogleMaps'));
const LoadFormations = loadable(() => import('./pages/LoadFormations'));
const TransportDrafts = loadable(() => import('./pages/TransportDrafts'));
const CargoClosingWizard = loadable(() => import('./pages/CargoClosingWizard'));
const LoanRequest = loadable(() => import('./pages/LoanRequest'));
const ReceivingConference = loadable(() =>
  import('./pages/ReceivingConference')
);
const TagSignature = loadable(() => import('./pages/TagSignature'));
const PalletManagements = loadable(() => import('./pages/PalletManagements'));
const StockTransfers = loadable(() => import('./pages/StockTransfers'));
const PurchaseDownPayments = loadable(() =>
  import('./pages/PurchaseDownPayments')
);
const StockMoving = loadable(() => import('./pages/StockMoving'));
const AllowPickingOrders = loadable(() => import('./pages/AllowPickingOrders'));
const Banknotes = loadable(() => import('./pages/Banknotes'));
const ReleaseConferences = loadable(() => import('./pages/ReleaseConferences'));
const PickingOrder = loadable(() => import('./pages/PickingOrder'));
const UserPage = loadable(() => import('./pages/UserPage'));
const ExternalPage = loadable(() => import('./pages/ExternalPage'));
const UserPages = loadable(() => import('./pages/UserPages'));
const RepackingWizard = loadable(() => import('./pages/RepackingWizard'));
const TransferBetweenBranches = loadable(() =>
  import('./pages/TransferBetweenBranches')
);
const ExpenseAdvanceEffective = loadable(() =>
  import('./pages/ExpenseAdvanceEffective')
);
const ExpenseAdvanceApproval = loadable(() =>
  import('./pages/ExpenseAdvanceApproval')
);
const ExpensesControl = loadable(() => import('./pages/ExpensesControl'));

const FinancialConciliation = loadable(() =>
  import('./pages/FinancialConciliation')
);
const ExpensePending = loadable(() => import('./pages/ExpensePending'));
const Signatures = loadable(() => import('./pages/Signatures'));
const ExpensesTypes = loadable(() => import('./pages/ExpensesTypes'));
const EmailTemplates = loadable(() => import('./pages/EmailTemplates'));
const ImplementationProjects = loadable(() =>
  import('./pages/ImplementationProjects')
);
const ImplementationProjectsExecution = loadable(() =>
  import('./pages/ImplementationProjectsExecution')
);
const AccessLogs = loadable(() => import('./pages/AccessLogs'));
const EmailDistribuitionList = loadable(() =>
  import('./pages/EmailDistribuitionList')
);
const ResultsAnalyzer = loadable(() => import('./pages/ResultsAnalyzer'));
const ResultsAnalyzerExec = loadable(() =>
  import('./pages/ResultsAnalyzerExec')
);
const ProductTrees = loadable(() => import('./pages/ProductTrees'));
const Campaigns = loadable(() => import('./pages/Campaigns'));

const routes = [
  <AsyncRoute
    path="/PurchaseQuotationsSupplier/:externalToken"
    component={PurchaseQuotationsSupplier}
    exact
    isPrivate={false}
  />,

  <AsyncRoute path="/BusinessPartners" component={BusinessPartners} exact />,
  <AsyncRoute path="/ExpensesAdvance" component={ExpensesAdvance} exact />,
  <AsyncRoute path="/Expenses" component={Expenses} exact />,
  <AsyncRoute path="/FinancialPolicies" component={FinancialPolicies} exact />,
  <AsyncRoute path="/SalesOrders/:docEntry" component={SalesOrders} />,
  <AsyncRoute path="/SalesOrders" component={SalesOrders} exact />,
  <AsyncRoute path="/PurchaseOrders/:docEntry" component={PurchaseOrders} />,
  <AsyncRoute path="/PurchaseOrders" component={PurchaseOrders} exact />,
  <AsyncRoute path="/SystemLogs/:ref" component={SystemLogs} exact />,
  <AsyncRoute path="/SystemLogs/" component={SystemLogs} exact />,
  <AsyncRoute
    path="/PurchaseOrdersImport/:docEntry"
    component={PurchaseOrdersImport}
  />,
  <AsyncRoute
    path="/PurchaseOrdersImport"
    component={PurchaseOrdersImport}
    exact
  />,
  <AsyncRoute path="/SalesQuotations/:docEntry" component={SalesQuotations} />,
  <AsyncRoute path="/SalesQuotations" component={SalesQuotations} exact />,
  <AsyncRoute path="/BusinessPartners" component={BusinessPartners} />,
  <AsyncRoute
    path="/BusinessPartnerWizard"
    component={BusinessPartnerWizard}
  />,
  <AsyncRoute
    path="/BusinessPartnerWizard/:cnpj"
    component={BusinessPartnerWizard}
  />,
  <AsyncRoute
    path="/BusinessPartnerWizardCRM"
    component={BusinessPartnerWizardCRM}
  />,

  <AsyncRoute path="/DefaultBranch" component={DefaultBranch} />,
  <AsyncRoute path="/Items" component={Items} />,
  <AsyncRoute path="/SalesOpportunities" component={SalesOpportunities} />,
  <AsyncRoute
    path="/SalesOpportunitiesKanban"
    component={SalesOpportunitiesKanban}
  />,
  <AsyncRoute path="/Activities" component={Activities} />,
  <AsyncRoute path="/PurchaseRequests" component={PurchaseRequests} />,
  <AsyncRoute
    path="/Proposals/:token"
    isPrivate={false}
    component={Proposals}
  />,
  <AsyncRoute path="/ReportQueries" component={ReportQueries} />,
  <AsyncRoute path="/BatchNumberDetails" component={BatchNumberDetails} />,
  <AsyncRoute path="/SerialNumberDetails" component={SerialNumberDetails} />,

  <AsyncRoute path="/SalesInvoices/:docEntry" component={SalesInvoices} />,
  <AsyncRoute path="/SalesInvoices" component={SalesInvoices} exact />,

  <AsyncRoute path="/PurchaseInvoices" component={PurchaseInvoices} exact />,

  <AsyncRoute path="/ReturnRequests" component={ReturnRequests} exact />,
  <AsyncRoute
    path="/RequestForQuotationsWizard"
    component={RequestForQuotationsWizard}
    exact
  />,

  <AsyncRoute
    path="/PurchaseQuotations"
    component={PurchaseQuotations}
    exact
  />,
  <AsyncRoute
    path="/PurchaseQuotationClient"
    component={PurchaseQuotationClient}
    isPrivate={false}
    exact
  />,
  <AsyncRoute
    path="/PurchaseQuotations/:docEntry"
    component={PurchaseQuotations}
  />,
  <AsyncRoute
    path="/PurchaseDownPayments"
    component={PurchaseDownPayments}
    exact
  />,
  <AsyncRoute
    path="/PurchaseDownPayments/:docEntry"
    component={PurchaseDownPayments}
  />,
  <AsyncRoute
    path="/BusinessPartnersAccess"
    component={BusinessPartnersAccess}
    exact
  />,
  <AsyncRoute
    path="/PurchaseQuotationsSupplierModels"
    component={PurchaseQuotationsSupplierModels}
    exact
  />,
  <AsyncRoute path="/SalesRoutes" component={SalesRoutes} exact />,
  <AsyncRoute
    path="/BusinessPartnersAccess/:docEntry"
    component={BusinessPartnersAccess}
  />,

  <AsyncRoute
    path="/BusinessPartnersPre"
    component={BusinessPartnersPre}
    exact
  />,
  <AsyncRoute
    path="/BusinessPartnersCRM"
    component={BusinessPartnersCRM}
    exact
  />,
  <AsyncRoute path="/Trucks" component={Trucks} exact />,
  <AsyncRoute path="/WarehouseRoutes" component={WarehouseRoutes} exact />,

  <AsyncRoute path="/TruckTypes" component={TruckTypes} exact />,
  <AsyncRoute path="/DefectiveItem" component={DefectiveItem} exact />,
  <AsyncRoute
    path="/DistributionReconciliation"
    component={DistributionReconciliation}
    exact
  />,
  <AsyncRoute
    path="/InventoryTransferRequests"
    component={InventoryTransferRequests}
    exact
  />,
  <AsyncRoute path="/GoogleMaps" component={GoogleMaps} exact />,
  <AsyncRoute path="/LoadFormations" component={LoadFormations} exact />,
  <AsyncRoute path="/TransportDrafts" component={TransportDrafts} exact />,
  <AsyncRoute
    path="/CargoClosingWizard"
    component={CargoClosingWizard}
    exact
  />,
  <AsyncRoute path="/LoanRequest" component={LoanRequest} exact />,
  <AsyncRoute
    path="/ReceivingConference"
    component={ReceivingConference}
    exact
  />,
  <AsyncRoute path="/TagSignature" component={TagSignature} exact />,
  <AsyncRoute path="/PalletManagements" component={PalletManagements} exact />,
  <AsyncRoute path="/StockTransfers" component={StockTransfers} exact />,
  <AsyncRoute path="/StockMoving" component={StockMoving} exact />,
  <AsyncRoute
    path="/AllowPickingOrders"
    component={AllowPickingOrders}
    exact
  />,
  <AsyncRoute path="/Banknotes" component={Banknotes} exact />,
  <AsyncRoute
    path="/ReleaseConferences"
    component={ReleaseConferences}
    exact
  />,
  <AsyncRoute path="/PickingOrder" component={PickingOrder} exact />,
  <AsyncRoute path="/UserPages" component={UserPages} exact />,
  <AsyncRoute path="/UserPage/:hash" component={UserPage} exact />,
  <AsyncRoute path="/ExternalPage/:hash" component={ExternalPage} exact />,
  <AsyncRoute path="/RepackingWizard" component={RepackingWizard} />,

  <AsyncRoute
    path="/TransferBetweenBranches"
    component={TransferBetweenBranches}
  />,
  <AsyncRoute
    path="/ExpenseAdvanceEffective"
    component={ExpenseAdvanceEffective}
  />,
  <AsyncRoute
    path="/ExpenseAdvanceApproval"
    component={ExpenseAdvanceApproval}
  />,
  <AsyncRoute path="/ExpensesControl" component={ExpensesControl} />,
  <AsyncRoute
    path="/FinancialConciliation"
    component={FinancialConciliation}
  />,
  <AsyncRoute path="/ExpensePending" component={ExpensePending} />,
  <AsyncRoute path="/Signatures" component={Signatures} exact />,
  <AsyncRoute path="/ExpensesTypes" component={ExpensesTypes} />,
  <AsyncRoute path="/EmailTemplates" component={EmailTemplates} />,
  <AsyncRoute
    path="/ImplementationProjects"
    component={ImplementationProjects}
  />,
  <AsyncRoute
    path="/ImplementationProjectsExecution"
    component={ImplementationProjectsExecution}
  />,
  <AsyncRoute path="/AccessLogs" component={AccessLogs} />,
  <AsyncRoute
    path="/EmailDistribuitionList"
    component={EmailDistribuitionList}
  />,
  <AsyncRoute path="/ResultsAnalyzer" component={ResultsAnalyzer} />,
  <AsyncRoute path="/ResultsAnalyzerExec" component={ResultsAnalyzerExec} />,
  <AsyncRoute path="/ProductTrees" component={ProductTrees} />,
  <AsyncRoute path="/Campaigns" component={Campaigns} />,
  <AsyncRoute path="/Vendors" component={Vendors} />,
];

export default routes;
